import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import {
  Connected,
  State,
  RouteSelectors,
  // dispatch,
  RouteName,
  UserManagerSelectors,
} from "./connected";

import "./not-found-page";
import "./dash-board";
import "./matrix-page";
import "./app-home";
import "./courses-page";
import "./course-detail-page";
import "./media-editor";
import "./users-page";
import "./wordcast-page";

import { IUser } from "../state/models/usermanager";

@customElement("app-router")
export class AppRouter extends Connected {
  @state() user: IUser;
  @state() page: string;
  @state() params: {
    [key: string]: any;
  };
  @state() queries: any;

  mapState(state: State) {
    return {
      user: UserManagerSelectors.user(state),
      page: RouteSelectors.page(state),
      params: RouteSelectors.params(state),
      queries: RouteSelectors.queries(state),
    };
  }

  render() {
    if (!this.user) {
      return html`<app-home></app-home>`;
    }

    switch (this.page) {
      case RouteName.logout:
        return html`<app-home></app-home>`;
      case RouteName.home:
        return html`<app-home></app-home>`;
      case RouteName.matrix:
        return html`<matrix-page></matrix-page>`;
      case RouteName.dashboard:
        return html`<dash-board></dash-board>`;
      case RouteName.courses:
        return html`<courses-page></courses-page>`;
      case RouteName.courseDetail:
        return html`<course-detail-page></course-detail-page>`;
      case RouteName.mediaDetail:
        return html`<media-editor></media-editor>`;
      case RouteName.users:
        return html`<users-page></users-page>`;
      case RouteName.wordcast:
        return html`<wordcast-page></wordcast-page>`;
      default:
        return html`<not-found-page></not-found-page>`;
    }
  }
}
