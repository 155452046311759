import { css } from "lit";

export const SharedStyles = css`
  ul li {
    background: transparent;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  a {
    background-color: transparent;
    color: white;
    padding: 0.025rem;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
  }

  a:hover {
    background-color: #555;
  }

  a:active {
    background-color: lightblue;
  }

  a:visited {
    background-color: #ccc;
  }

  h3.header {
    text-align: center;
    font-family: var(--app-font-family);
    font-size: 1.5rem;
    color: rgb(var(--sl-color-primary-500) / 100%);
  }

  .backText {
    z-index: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-family: var(--app-font-family);
    font-size: 10vw;
    font-weight: 700;
    letter-spacing: -2px;
    line-height: 100px;
    white-space: nowrap;
    color: #fff;
    opacity: 0.005;
    height: 100px;
  }

  .reading-text {
    // z-index: 99999;
    font-family: var(--app-font-family);
    font-size: 1rem;
    color: white;
    text-align: left;
    line-height: 2rem;
    margin: 0;
    padding: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: solid 1px tomato;
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  .tx_align_horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .tx_align_vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .bg {
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    background-size: 2000px 2000px, 2000px 2000px, 10px 10px, 10px 10px;
    background-position: -1px -1px, -1px -1px, -1px -1px, -1px -1px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3) 1px, transparent 1px),
      linear-gradient(90deg, rgba(177, 171, 245, 0.05) 1px, transparent 1px),
      linear-gradient(rgba(177, 171, 245, 0.05) 1px, transparent 1px),
      linear-gradient(90deg, rgba(177, 171, 245, 0.05) 1px, transparent 1px);
  }

  .responsive_grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 0.25rem;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
  }

  .container {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0px;
    left: 0px;
    margin: 0px;
    padding: 0px;
  }
`;

export const cssScrollbar = css`
  /* width */
  ::-webkit-scrollbar {
    width: 0.15rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const cssAppLayout = css`
  .grid_container {
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 2rem 20vw auto 15vw;
    grid-template-rows: 1fr 1.75rem 1rem;
    grid-template-areas:
      "menu   left   main   right"
      "footer footer footer footer"
      "footsy footsy footsy footsy";
  }

  .left_toolbar {
    grid-area: menu;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
    margin: 0;
    padding-bottom: 1rem;
  }

  .left_panel_container {
    grid-area: left;
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    padding-top: 1rem;
  }

  .mainboard_container {
    grid-area: main;
    margin: 0;
    padding: 0;
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .board_content_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .board_controls {
    z-index: 9999;
    display: flex;
    flex-flow: row;
    align-items: center;
    align-content: center;
    text-align: center;
    margin: 0px;
    padding: 0px;
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
  }

  .control-item {
    cursor: pointer;
  }

  .right_panel_container {
    grid-area: right;
    display: flex;
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
    font-size: 0.5rem;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  #rooms_drawer {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .footer {
    grid-area: footer;
    display: flex;
    flex-direction: row;
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
  }

  .footsy {
    grid-area: footsy;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: space-between;
    color: rgba(255, 255, 255, 0.025);
    font-size: 0.5rem;
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
  }

  .footer__copyright {
  }

  sl-icon-button {
    elevation: --sl-shadow-small;
  }

  sl-details::part(base) {
    font-size: 0.75rem;
    border: none;
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
  }

  sl-menu::part(base) {
    margin: 0;
    padding: 0;
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
  }

  sl-menu-item::part(base) {
    margin: 0;
    padding: 0;
    font-size: 0.75rem;
  }

  sl-select::part(menu) {
    margin: 0;
    padding: 0;
    background-color: rgb(var(--sl-color-blue-gray-50) / 100%);
  }

  .tx_avatar__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    font-size: 0.5rem;
  }

  .footer_avatar_header {
    font-size: 0.75rem;
    padding-left: 3rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
  }

  .room_header {
    display: flex;
    flex-direction: row;
  }

  .small_avatar {
    --size: 1rem;
  }

  .mid_avatar {
    --size: 2rem;
  }

  #iframed_drawer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }

  sl-drawer::part(header) {
    height: 5rem;
    overflow: hidden;
    // background: transparent;
    background-color: rgb(var(--sl-color-primary-50) / 75%);
    margin: 0;
    padding: 0;
  }

  sl-drawer::part(footer) {
    display: flex;
    flex-direction: row;
    align-items: right;
    justify-content: space-between;
    background-color: rgb(var(--sl-color-primary-50) / 75%);
    margin: 0;
    padding: 0;
  }

  sl-drawer::part(base) {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  sl-drawer::part(panel) {
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-color: var(--app-dark-blue-background-color);
  }

  sl-drawer::part(overlay) {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  sl-drawer::part(body) {
    overflow: hidden; // make the scrollbar of the drawer disappear
    margin: 0;
    padding: 0;
  }

  #calendar_details {
    position: relative;
  }

  alert-duration sl-alert {
    margin-top: var(--sl-spacing-medium);
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
  }

  sl_details::part(header) {
    margin-top: 0.5rem;
    padding: 0;
  }

  sl-dropdown::part(base) {
    margin: 0;
    padding: 0;
    // background-color: rgb(var(--sl-color-neutral-500) / 5%);
  }

  sl-dropdown::part(panel) {
    margin: 0;
    padding: 0;
    // background-color: rgb(var(--sl-color-neutral-500) / 5%);
  }

  sl-dropdown::part(trigger) {
    // background-color: rgb(var(--sl-color-neutral-500) / 5%);
    margin: 0;
    padding: 0;
  }

  sl-menu::part(base) {
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
  }

  .popout_menu {
    overflow: hidden;
    background-color: var(--app-dark-blue-background-color);
    margin: 0;
    padding: 0;
  }

  .popout_menu_item {
    margin: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }

  sl-button::part(base) {
    background-color: transparent; // rgb(var(--sl-color-neutral-500) / 5%);
  }

  .button_icon {
    padding: 0.25rem;
    color: rgb(var(--sl-color-yellow-500) / 75%);
  }

  .button_text {
    padding: 0.25rem;
    color: rgb(var(--sl-color-blue-700) / 75%);
  }
`;

export const cssChatbox = css`
  #tx_chat_window {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }

  #inputcontrols {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  #controls {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  #mathinput {
    display: none;
    width: 30vw;
    height: 1.5rem;
    font-size: 0.75rem;
    margin: 0.1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    border-radius: 6px;
    background-color: rgba(62, 158, 232, 0.15);
    --hue: 53 !important;
    --caret: white !important;
    --highlight: transparent;
  }

  #textinput::part(base) {
    width: 30vw;
    height: 1.5rem;
    font-size: 0.75rem;
    margin: 0.1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    border-radius: 6px;
    color: #dde;
    background-color: rgba(62, 158, 232, 0.15);
    overflow: hidden;
  }

  #textinput::part(textarea) {
    overflow: hidden;
  }

  .msgbox {
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.25rem;
  }

  .msgbox_header {
    display: flex;
  }

  .sender_profile {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 0.11em;
    padding-right: 1em;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
  }

  ol.no_bullets {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  sl-avatar.sender {
    --size: 1.5rem;
  }

  .timestamp {
    font-family: "Trebuchet MS", sans-serif;
    font-size: 0.5rem;
    color: rgba(168, 247, 247, 0.25);
    padding: 5px;
  }

  .msg_text {
    font-size: 0.75rem;
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 2rem;

  .tx_msg_audio_player {
    color: white;
    height: 1em;
    background: rgba(255, 255, 255, 0.1);
  }

`;

export const cssVoiceRecorder = css`
  #voicemailwriter {
    z-index: 9999;
    margin: 0;
    padding: 0.25rem;
  }
`;

export const cssAttachments = css`
  file-attachment {
    display: block;
    text-align: center;
    border: none;
    padding: 0;
    // font-size: 1rem;
  }

  input {
    font-size: inherit;
  }

  input[type="file"] {
    display: none;
  }

  .fileupload {
    cursor: pointer;
  }

  #attachments_preview {
    display: none;
    background-color: rgba(255, 255, 255, 0.25);
    padding: 1em;
  }

  #file_drawer_container {
    display: none;
    background-color: --sl-color-blue-50;
  }
`;

export const cssQuizlet = css`
  .quizlet-container {
    font-family: var(--app-font-family);
    margin: 0;
    padding: 1rem;
  }

  .quizlet_header {
    font-size: 1.5rem;
    color: rgb(var(--sl-color-primary-500) / 100%);
    margin: 0;
    padding: 0.15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .quizlet_progress {
    font-size: 1rem;
    color: rgb(var(--sl-color-primary-300) / 100%);
    margin: 0;
    padding: 0.5rem;
    display: flex;
    flex-flow: wrap row;
    align-items: center;
    justify-content: flex-end;
  }

  span.score {
    color: yellow;
    font-family: var(--app-font-family);
    font-size: 1.5rem;
    margin: 0;
    padding: 0.25rem;
  }

  .question {
    margin: 0;
    padding: 0.15rem;
    font-size: 1.5rem;
    color: rgb(var(--sl-color-lime-600) / 100%);
  }

  sl-radio::part(label) {
    font-family: var(--app-font-family);
    font-size: 1rem;
    color: rgb(var(--sl-color-lime-950) / 75%);
  }

  sl-radio::part(control) {
    // background-color: transparent;
  }

  sl-radio::part(base) {
    background-color: transparent;
  }

  sl-button::part(label) {
    font-family: var(--app-font-family);
    font-size: 1.5rem;
    color: rgb(var(--sl-color-primary-500) / 100%);
  }

  sl-button::part(suffix) {
    font-size: 1rem;
    color: rgb(var(--sl-color-primary-500) / 100%);
  }

  sl-button::part(base) {
    margin: auto;
    padding: 5px;
    background-color: rgb(var(--sl-color-neutral-500) / 10%);
  }

  .next_button {
    display: flex;
    flex-flow: wrap row;
    justify-content: flex-end;
    margin: 0;
    padding: 0.5rem;
  }

  .quizlet_question_choices {
    margin: 0;
    padding: 0.15rem;
  }

  .progress_ring {
    --size: 3.8rem;
    --track-width: 0.15rem;
    --indicator-color: rgb(var(--sl-color-lime-600) / 100%);
  }

  .progress_bar_container {
    margin: 0;
    padding-top: 0.15rem;
    width: 100%;
  }

  .progress_bar {
    --height: 1rem;
    --indicator-color: rgb(var(--sl-color-lime-600) / 100%);
  }

  sl-progress-ring::part(base) {
    background-color: transparent;
  }

  sl-radio-group::part(base) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    grid-gap: 0.15rem;
    margin: 0;
    padding: 0.15rem;
  }

  sl-radio-group::part(label) {
    font-family: var(--app-font-family);
    font-size: var(--sl-font-size-3x-small);
    color: rgb(var(--sl-color-primary-300) / 100%);
  }
`;

export const cssMainGrid = css`
  .main {
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-gap: 0.15rem;
    grid-template-columns: 2.5rem 20vw 1fr;
    grid-template-rows: 2.5rem auto 1rem;
    grid-template-areas:
      "leftmenubar  searchbar header"
      "leftmenubar main main"
      "leftmenubar footsy footsy";
  }

  .searchbar {
    grid-area: searchbar;
    padding: 0.5rem;
  }

  .header {
    grid-area: header;
    padding: 0.5rem;
  }

  .leftmenubar {
    grid-area: leftmenubar;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
    margin: 0;
    padding-bottom: 1rem;
  }

  .mainboard {
    grid-area: main;
    margin: 0;
    padding: 0;
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .footsy {
    grid-area: footsy;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    color: rgba(255, 255, 255, 0.025);
    font-size: 0.5rem;
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
  }
`;

export const cssResponsiveCardGrid = css`
  .card-grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    gap: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .card {
    padding: 0.5rem;
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
  }

  .card [slot="header"] {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card h3 {
    margin: 0;
  }

  .card sl-icon-button {
    font-size: var(--sl-font-size-medium);
  }

  .card [slot="footer"] {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const cssFormStyle = css`
  .form_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent; //rgb(var(--sl-color-primary-50) / 55%);
    position: relative;
    width: 100%;
    height: 100%;
  }
  .form_body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 2rem;
  }
`;
