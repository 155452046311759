import { createModel, RoutingState } from "@captaincodeman/rdx";
import { State, Store } from "../store";
// import { createSelector } from "reselect";
import { RouteName } from "../config";

export interface IAppState {
  page: string;
  error: string;
}

const initialState = <IAppState>{
  page: RouteName.home,
  error: "",
};

export default createModel({
  state: initialState,

  reducers: {
    setPage(state, page: RouteName) {
      return {
        ...state,
        page,
      };
    },

    reset() {
      return initialState;
    },
  },

  effects(store: Store) {
    const dispatch = store.getDispatch();
    return {
      "routing/change": async function (payload: RoutingState<RouteName>) {
        dispatch.app.setPage(payload.page);
        switch (payload.page) {
          case RouteName.matrix:
            break;
          case RouteName.courses:
            break;
          case RouteName.courseDetail:
            dispatch.resource.fetchMediaList({
              courseId: payload.params.courseId,
              reload: false,
            });
            break;
          case RouteName.mediaDetail:
            dispatch.resource.loadActivatedMedia(payload.params.mediaId);
            dispatch.mediaplayer.activate();
            break;
          case RouteName.users:
            dispatch.usermanager.getUsers(100); // hardcoded/ need to change to pagination
            break;
          case RouteName.dashboard:
            dispatch.resource.fetchCourseList();
            break;
          case RouteName.logout:
            dispatch.usermanager.logout();
            break;
          case RouteName.notfound:
            localStorage.clear();
            window.history.pushState({}, "", "/");
            location.reload();
            break;
          default:
        }
      },
    };
  },
});

const getState = (state: State) => state.app;

export namespace AppSelectors {}
