import { createModel } from "@captaincodeman/rdx";
import { State, Store } from "../store";
import { createSelector } from "reselect";

export type IMediaPlayerEvent = {
  playHeadPosition?: number;
  // isPlaying?: boolean;
  isTranscriptOn?: boolean;
  isMutedByHost?: boolean;
};

export interface IMediaPlayerState {
  active: boolean;
  playHeadPosition: number;
  // isPlaying: boolean;
  isTranscriptOn: boolean;
  isMutedByHost: boolean;
  error?: String;
}

const initialState = <IMediaPlayerState>{
  active: false,
  playHeadPosition: 0,
  // isPlaying: false,
  isTranscriptOn: true,
  isMutedByHost: false,
  error: "",
};

export default createModel({
  state: initialState,
  reducers: {
    activate(state: IMediaPlayerState) {
      return { ...state, active: true };
    },

    updateAll(state: IMediaPlayerState, playerState: IMediaPlayerState) {
      return {
        ...state,
        active: playerState.active,
        playHeadPosition: playerState.playHeadPosition,
        // isPlaying: playerState.isPlaying,
        isTranscriptOn: playerState.isTranscriptOn,
        isMutedByHost: playerState.isMutedByHost,
      };
    },

    setPlayHeadPosition(state: IMediaPlayerState, playHeadPosition: number) {
      return { ...state, playHeadPosition };
    },

    // togglePlayPause(state: IMediaPlayerState, isPlaying: boolean) {
    //   return { ...state, isPlaying };
    // },

    toggleTranscriptOnOff(state: IMediaPlayerState, isTranscriptOn: boolean) {
      return { ...state, isTranscriptOn };
    },

    toggleMuteUnmute(state: IMediaPlayerState, isMutedByHost: boolean) {
      return { ...state, isMutedByHost };
    },

    errored(state, err) {
      return { ...state, error: err };
    },

    reset() {
      return initialState;
    },
  },

  effects(store: Store) {
    const dispatch = store.getDispatch();
    return {
      update(payload: IMediaPlayerEvent) {
        if (!payload) return;

        if (payload.playHeadPosition !== undefined) {
          dispatch.mediaplayer.setPlayHeadPosition(payload.playHeadPosition);
        }

        if (payload.isMutedByHost !== undefined) {
          dispatch.mediaplayer.toggleMuteUnmute(payload.isMutedByHost);
        }

        if (payload.isTranscriptOn !== undefined) {
          dispatch.mediaplayer.toggleTranscriptOnOff(payload.isTranscriptOn);
        }

        // if (payload.isPlaying !== undefined) {
        //   dispatch.mediaplayer.togglePlayPause(payload.isPlaying);
        // }
      },

      deActivate() {
        dispatch.mediaplayer.reset();
      },
    };
  },
});

const getState = (state: State) => state.mediaplayer;

export namespace MediaPlayerSelectors {
  export const playerActivated = createSelector(
    [getState],
    (state) => state.active
  );

  export const playHeadPosition = createSelector(
    [getState],
    (state) => state.playHeadPosition
  );

  // export const isPlaying = createSelector(
  //   [getState],
  //   (state) => state.isPlaying
  // );

  export const isTranscriptOn = createSelector(
    [getState],
    (state) => state.isTranscriptOn
  );

  export const isMutedByHost = createSelector(
    [getState],
    (state) => state.isMutedByHost
  );
}
