import { SlButton, SlDrawer, SlSelect } from "@shoelace-style/shoelace";
import { css, html, PropertyValues } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import {
  cssAppLayout,
  cssMainGrid,
  cssResponsiveCardGrid,
  cssScrollbar,
  SharedStyles,
} from "../components/sharedStyles";
import { copyrighter } from "../config";
import { IMediaInfo, ResourceSelectors } from "../state/models/resource";
import { Connected, dispatch, RouteName, State } from "./connected";

@customElement("course-detail-page")
export class CourseDetailPage extends Connected {
  @state() private currentMediaList: IMediaInfo[];
  @state() private availableMediaList: IMediaInfo[];
  // @state() private editMode: boolean = false;
  @state() private fetchMediaListCompleted: boolean;

  @query("#addNewMediaDrawer") addNewMediaDrawer: SlDrawer;

  mapState(state: State) {
    return {
      currentMediaList: ResourceSelectors.currentMediaList(state),
      availableMediaList: ResourceSelectors.availableMediaList(state),
      fetchMediaListCompleted: ResourceSelectors.fetchMediaListCompleted(state),
    };
  }

  onClickShowMediaDrawer() {
    dispatch.resource.fetchNewlyAddedMediaList();
    this.addNewMediaDrawer.show();
  }

  onClickReloadMediaList() {
    dispatch.resource.reloadMediaList();
  }

  onClickUpdateMediaRecord(e: any) {
    console.log("onClickUpdateMediaRecord", e.currentTarget.id);
    dispatch.resource.addUpdateMediaRecord({
      mediaId: e.currentTarget.id,
      overwrite: true,
    });
  }

  get htmlMediaCards() {
    if (!Array.isArray(this.currentMediaList))
      return html` <div class="centered">
        <h2>loading...</h2>
        <sl-spinner
          style="font-size: 5rem;  --track-color: deepred; --indicator-color:yellow;"
        ></sl-spinner>
      </div>`;

    return html`${this.currentMediaList.map(
      (x) => html`<sl-card class="card">
        <div slot="header">
          <sl-button
            id=${x.mediaId}
            variant="text"
            size="small"
            @click=${this.onClickUpdateMediaRecord}
          >
            <sl-icon class="button_icon" slot="prefix" name="gear"></sl-icon>
            <span class="button_text">edit</span>
          </sl-button>

          <a href="/${RouteName.mediaDetail}/${x.mediaId}">
            <sl-button variant="text" size="small">
              <sl-icon
                slot="prefix"
                name="ticket-detailed"
                class="button_icon"
              ></sl-icon>
              <span class="button_text">show detail</span>
            </sl-button></a
          >
        </div>
        <div>${x.title}</div>
      </sl-card>`
    )}`;
  }

  render() {
    return html`<sl-resize-observer
      ><div class="main">
        <div class="searchbar">
          <sl-input
            type="search"
            placeholder="search media"
            size="small"
            pill
            autofocus
            clearable
            ><sl-icon name="search" slot="prefix" class="button_icon"></sl-icon
          ></sl-input>
        </div>

        <div class="header">
          <sl-button
            id="reload_media_list"
            variant="default"
            size="small"
            pill
            @click=${this.onClickReloadMediaList}
            ><sl-icon
              slot="prefix"
              name="arrow-clockwise"
              class="button_icon"
            ></sl-icon
            ><span class="button_text">reload media list</span></sl-button
          >

          <sl-button
            id="add_new_media"
            variant="default"
            size="small"
            pill
            @click=${this.onClickShowMediaDrawer}
            ><sl-icon
              slot="prefix"
              name="folder-plus"
              class="button_icon"
            ></sl-icon
            ><span class="button_text">update media list</span></sl-button
          >

          <sl-drawer
            id="addNewMediaDrawer"
            label="updating medialist..."
            placement="end"
            style="--size: 50vw;"
          >
            ${!this.fetchMediaListCompleted
              ? html` <div class="centered">
                  <h2>loading...</h2>
                  <sl-spinner
                    style="font-size: 3rem;  --track-color: blue; --indicator-color:yellow;"
                  ></sl-spinner>
                </div>`
              : html`
                  ${this.availableMediaList.length < 1
                    ? html`<div class="centered">
                        <h2>the media list is not updated yet...</h2>
                      </div>`
                    : html` <div class="medialist">
                          <ul>
                            ${this.availableMediaList.map(
                              (x) =>
                                html`
                                  <li id=${x.mediaId} value=${x.title}>
                                    ${x.title}
                                  </li>
                                `
                            )}
                          </ul>
                        </div>
                        <sl-badge slot="footer" variant="primary" pill pulse
                          >updated: ${this.availableMediaList.length}</sl-badge
                        >`}
                `}
          </sl-drawer>
        </div>
        <div id="mainboard_container" class="mainboard card-grid">
          ${this.htmlMediaCards}
        </div>
        <div class="footsy footer__copyright">
          <p>&copy; ${copyrighter}</p>
        </div>
      </div></sl-resize-observer
    >`;
  }

  static get styles() {
    return [
      SharedStyles,
      cssScrollbar,
      cssAppLayout,
      cssMainGrid,
      cssResponsiveCardGrid,
      css`
        .medialist {
          // overflow: auto;
        }

        sl-drawer::part(body) {
          overflow: auto;
        }
      `,
    ];
  }
}
