import { SlButton, SlDrawer } from "@shoelace-style/shoelace";
import { css, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import {
  Connected,
  State,
  UserManagerSelectors,
  RouteName,
  dispatch,
} from "./connected";
import {
  cssAppLayout,
  cssMainGrid,
  cssResponsiveCardGrid,
  cssScrollbar,
  SharedStyles,
} from "../components/sharedStyles";
import { copyrighter } from "../config";
import {
  ICourseInfo,
  IMediaDirectory,
  IMediaInfo,
  ResourceSelectors,
} from "../state/models/resource";

import '../components/course-form'

@customElement("courses-page")
export class CoursesPage extends Connected {
  @state() username: string;
  @state() courseList: ICourseInfo[];
  @state() mediaList: Map<string, IMediaInfo[]>;
  @state() mediaDirectories: IMediaDirectory[];
  @state() editMode: boolean = false;
  @state() targetCourse: ICourseInfo;

  @query("#addNewCourseDrawer") addNewCourseDrawer: SlDrawer;
  @query("#editCourseDrawer") editCourseDrawer: SlDrawer;

  mapState(state: State) {
    return {
      mediaDirectories: ResourceSelectors.mediaDirectories(state),
      courseList: ResourceSelectors.courseList(state),
      mediaList: ResourceSelectors.mediaList(state),
      username: UserManagerSelectors.username(state),
    };
  }

  onClickShowEditCourseDrawer(e: PointerEvent) {
    const target = e.currentTarget as SlButton;
    this.editMode = true;
    this.targetCourse = this.courseList.find((x) => x.courseId === target.id)!;
    this.editCourseDrawer.show();
  }

  onEditCourseDetail(e: CustomEvent) {
    const { courseId, ...updates } = e.detail;
    dispatch.resource.updateCourseDetail({ courseId, updates });
    this.editCourseDrawer.hide();
  }

  onClickAddNewCourseDrawer() {
    dispatch.resource.fetchMediaDirectories();
    this.editMode = false;
    this.addNewCourseDrawer.show();
  }

  onAddNewCourse(e: CustomEvent) {
    dispatch.resource.addCourse(e.detail);
    this.addNewCourseDrawer.hide();
  }

  get htmlCourseCards() {
    if (this.courseList.length < 1)
      return html`
        <div class="centered">
          <h2>loading...</h2>
          <sl-spinner
            style="font-size: 3rem;  --track-color: blue; --indicator-color:yellow;"
          ></sl-spinner>
        </div>
      `;
    return html`${this.courseList.map(
      (x) => html`<sl-card class="card">
        <div slot="header">
          <sl-button
            id=${x.courseId}
            variant="text"
            size="small"
            @click=${this.onClickShowEditCourseDrawer}
          >
            <sl-icon class="button_icon" slot="prefix" name="gear"></sl-icon>
            <span class="button_text">edit</span>
          </sl-button>

          <a href="/${RouteName.courses}/${x.courseId}">
            <sl-button variant="text" size="small">
              <sl-icon
                slot="prefix"
                name="ticket-detailed"
                class="button_icon"
              ></sl-icon>
              <span class="button_text">show detail</span>
            </sl-button></a
          >

          <sl-drawer
            id="editCourseDrawer"
            label="Edit Course Detail"
            placement="end"
            style="--size: 35vw;"
          >
            <course-form
              isEdit=${this.editMode}
              .course=${this.targetCourse}
              @edit_course_detail=${this.onEditCourseDetail}
            ></course-form>
          </sl-drawer>
        </div>
        <div>${x.courseTitle}</div>
      </sl-card>`
    )}`;
  }

  get htmlAddNewCourse() {
    return html` <div class="header">
      <sl-button
        id="add_new_course"
        variant="default"
        size="small"
        pill
        @click=${this.onClickAddNewCourseDrawer}
        ><sl-icon slot="prefix" name="folder-plus" class="button_icon"></sl-icon
        ><span class="button_text">add courses</span></sl-button
      >

      <sl-drawer
        id="addNewCourseDrawer"
        label="Add New Course"
        placement="end"
        style="--size: 50vw;"
      >
        <course-form
          .mediaDirectories=${this.mediaDirectories}
          ?isEdit=${this.editMode}
          @add_new_course=${this.onAddNewCourse}
        ></course-form>
      </sl-drawer>
    </div>`;
  }

  render() {
    return html` <sl-resize-observer
      ><div class="main">
        <div class="searchbar">
          <sl-input
            type="search"
            placeholder="search course"
            size="small"
            pill
            autofocus
            clearable
            ><sl-icon name="search" slot="prefix" class="button_icon"></sl-icon
          ></sl-input>
        </div>
        ${this.htmlAddNewCourse}
        <div id="mainboard_container" class="mainboard card-grid">
          ${this.htmlCourseCards}
        </div>
        <div class="footsy footer__copyright">
          <p>&copy; ${copyrighter}</p>
        </div>
      </div></sl-resize-observer
    >`;
  }

  static get styles() {
    return [
      SharedStyles,
      cssScrollbar,
      cssAppLayout,
      cssMainGrid,
      cssResponsiveCardGrid,
      css``,
    ];
  }
}
