import { SlButton, SlDrawer } from "@shoelace-style/shoelace";
import { css, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import {
  Connected,
  State,
  UserManagerSelectors,
  dispatch,
  RouteSelectors,
} from "./connected";
import { getAspectRatio } from "../lib/utils/aspectratio";
import {
  cssAppLayout,
  cssMainGrid,
  cssResponsiveCardGrid,
  cssScrollbar,
  SharedStyles,
} from "../components/sharedStyles";
import { copyrighter } from "../config";
import { IUser } from "../state/models/usermanager";
import "../components/user-form";

@customElement("users-page")
export class UsersPage extends Connected {
  @state() users: IUser[];
  @state() username: string;
  @state() editMode: boolean = false;
  @state() targetUser: IUser;
  @state() aspectRatio: string;
  @state() page: string;

  @query("#registerNewUserDrawer") registerNewUserDrawer: SlDrawer;
  @query("#editUserDrawer") editUserDrawer: SlDrawer;
  @query("#mainboard_container") mainboard_container: HTMLDivElement;
  @query("sl-resize-observer") resizeObserver: any; // SlResizeObserver;

  mapState(state: State) {
    return {
      username: UserManagerSelectors.username(state),
      users: UserManagerSelectors.users(state),
      page: RouteSelectors.page(state),
    };
  }

  protected firstUpdated() {
    this.resizeObserver.addEventListener("sl-resize", (event: CustomEvent) => {
      let containerWidth = Math.round(this.mainboard_container.clientWidth);
      let containerHeight = Math.round(this.mainboard_container.clientHeight);
      this.aspectRatio = getAspectRatio(containerWidth, containerHeight);
    });
  }

  onClickShowEditUserDrawer(e: PointerEvent) {
    const target = e.currentTarget as SlButton;
    this.editMode = true;
    this.targetUser = this.users.find((x) => x.username === target.id)!;
    this.editUserDrawer.show();
  }

  onEditUserAccount(e: CustomEvent) {
    const { username, ...updates } = e.detail;
    dispatch.usermanager.updateUserAccount({ username, updates });
    this.editUserDrawer.hide();
  }

  onClickShowRegisterDrawer() {
    this.editMode = false;
    this.registerNewUserDrawer.show();
  }

  onRegisterNewUser(e: CustomEvent) {
    dispatch.usermanager.registerNewUser(e.detail);
  }

  get htmlUserCards() {
    if (!this.users)
      return html`<div class="centered">
        <h2>loading...</h2>
        <sl-spinner
          style="font-size: 5rem;  --track-color: deepred; --indicator-color:yellow;"
        ></sl-spinner>
      </div>`;

    return html`${this.users.map(
      (x) => html`<sl-card class="card">
        <div slot="header">
          <sl-avatar class="button_icon"></sl-avatar>
          <span class="button_text">${x.username}</span>

          <sl-button
            id=${x.username}
            variant="text"
            size="small"
            @click=${this.onClickShowEditUserDrawer}
          >
            <sl-icon class="button_icon" slot="prefix" name="gear"></sl-icon>
            <span class="button_text">edit</span>
          </sl-button>

          <sl-drawer
            id="editUserDrawer"
            label="Edit User Account"
            placement="end"
            style="--size: 25vw;"
          >
            <user-form
              isEdit=${this.editMode}
              .user=${this.targetUser}
              @edit_user_account=${this.onEditUserAccount}
            ></user-form>
          </sl-drawer>
        </div>
        <ul>
          <li>
            <span class="button_icon">
              <sl-icon slot="prefix" name="person-bounding-box"></sl-icon></span
            ><span class="button_text">matrix</span>
            ${x.matrixId}
          </li>

          <li>
            <span class="button_icon">
              <sl-icon slot="prefix" name="envelope-fill"></sl-icon
            ></span>
            <span class="button_text">email</span>
            ${x.email}
          </li>

          <li>
            <span class="button_icon">
              <sl-icon slot="prefix" name="person-lines-fill"></sl-icon></span
            ><span class="button_text">role </span>${x.role}
          </li>

          <li>
            <span class="button_icon">
              <sl-icon slot="prefix" name="activity"></sl-icon></span
            ><span class="button_text">status</span>
            ${x.inactive ? "inactive" : "active"}
          </li>
        </ul>

        <div slot="footer"></div>
      </sl-card>`
    )}`;
  }

  get htmlAddUser() {
    return html` <div class="header">
      <sl-button
        id="register_new_user"
        variant="text"
        size="small"
        @click=${this.onClickShowRegisterDrawer}
        ><sl-icon class="button_icon" slot="prefix" name="person-plus"></sl-icon
        ><span class="button_text">add user</span></sl-button
      >
      <sl-drawer
        id="registerNewUserDrawer"
        label="Create User Account"
        placement="end"
        style="--size: 25vw;"
      >
        <user-form @register_new_user=${this.onRegisterNewUser}></user-form>
      </sl-drawer>
    </div>`;
  }

  render() {
    return html`
      <sl-resize-observer
        ><div class="main">
          <div class="searchbar">
            <sl-input
              type="search"
              placeholder="search user"
              size="small"
              pill
              autofocus
              clearable
              ><sl-icon
                class="button_icon"
                name="search"
                slot="prefix"
              ></sl-icon
            ></sl-input>
          </div>

          ${this.htmlAddUser}

          <div id="mainboard_container" class="mainboard card-grid">
            ${this.htmlUserCards}
          </div>

          <div class="footsy footer__copyright">
            <p>&copy; ${copyrighter}</p>
          </div>
        </div></sl-resize-observer
      >
    `;
  }

  static get styles() {
    return [
      SharedStyles,
      cssScrollbar,
      cssAppLayout,
      cssMainGrid,
      cssResponsiveCardGrid,
      css``,
    ];
  }
}
