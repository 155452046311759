import { html, css, PropertyValues } from "lit";
import { customElement, state } from "lit/decorators.js";
import {
  Connected,
  State,
  UserManagerSelectors,
  dispatch,
  RouteName,
} from "./connected";
import "../components/logo-wheel";
import "../components/login-box";
import { cssAppLayout, SharedStyles } from "../components/sharedStyles";

@customElement("app-home")
export class AppHome extends Connected {
  @state() username: string;
  @state() isAdmin: boolean;

  mapState(state: State) {
    return {
      isAdmin: UserManagerSelectors.isAdmin(state),
      username: UserManagerSelectors.username(state),
    };
  }

  updated(changedProps: PropertyValues) {
    if (changedProps.has("username") && this.username && this.isAdmin) {
      dispatch.routing.replace(`/${RouteName.dashboard}/${this.username}`);
    }
  }

  onLoginWithPassword(e: CustomEvent) {
    dispatch.usermanager.signinEmailPassword(e.detail);
  }

  render() {
    return html`
      <div id="athome">
        <logo-wheel id="logowheel"></logo-wheel>
        <login-box @login_with_password=${this.onLoginWithPassword}></login-box>

        <h2 id="tagline1">
          <ul>
            <li><span style="color:yellow "> Learn to achieve</span></li>
            <li><span style="color:red"> Teach with passion</span></li>
          </ul>
        </h2>
      </div>
    `;
  }

  static get styles() {
    return [
      cssAppLayout,
      SharedStyles,
      css`
        #athome {
          display: flex;
          justify-content: center;
        }

        #logowheel {
          z-index: 0;
          width: 100vw;
          height: 100vh;
        }

        #tagline1 {
          position: absolute;
          bottom: 2rem;
          z-index: 1;
          text-align: center;
          font-family: var(--app-font-family);
        }

        #loginbox {
          z-index: 3;
        }
      `,
    ];
  }
}
