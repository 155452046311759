import { html, css, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("word-cast")
export class WordCast extends LitElement {
  @property({ type: String }) lemma: string;
  @property({ type: String }) pos: string;
  @property({ type: Array }) senses: string[];
  @property({ type: Array }) images: string[];
  @property({ type: Array }) examples: string[];

  get htmlImages() {
    if (!this.images) return;
    return html` ${this.images.map((x) => html` <img src=${x} />`)}`;
  }

  get htmlSenses() {
    if (!this.senses || !Array.isArray(this.senses)) return html``;

    if (this.senses.length > 1) {
      return html`<ol>
        ${this.senses.map((x) => html` <li>${x}</li> `)}
      </ol>`;
    } else {
      return html`<p>${this.senses[0]}</p>`;
    }
  }

  get htmlExamples() {
    if (!this.examples) return;
    return html`<ul>
      ${this.examples.map((x) => html` <li>${x}</li> `)}
    </ul>`;
  }

  render() {
    return html` <div class="main">
      <div class="header">
        <span class="lemma">${this.lemma}</span>
        <span class="pos">${this.pos}</span>
      </div>
      <div class="senses">${this.htmlSenses}</div>
      <div class="examples">${this.htmlExamples}</div>
      ${this.htmlImages}
    </div>`;
  }

  static get styles() {
    return css`
      .main {
        display: flex;
        flex-flow: row wrap;
        background: transparent;
        width: 100%;
        padding: 1rem;
        text-align: left;
      }

      img {
        padding: 0.5rem;
        border: 1px solid rgba(252, 255, 253, 0.25);
        box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
        // max-height: 100%;
      }

      span {
        padding: 0.25rem;
      }

      div {
        padding: 0.5rem;
      }

      .lemma {
        font-family: var(--sl-font-serif);
        font-size: 2.5rem;
        font-weight: bold;
        color: rgb(var(--sl-color-amber-700) / 100%);
      }

      .senses {
        font-family: var(--app-font-family);
        font-size: 1rem;
        font-weight: bold;
        color: rgb(var(--sl-color-blue-500) / 100%);
      }

      .examples {
        font-family: var(--app-font-family);
        font-size: 1rem;
        font-weight: bold;
        color: rgb(var(--sl-color-teal-800) / 100%);
      }

      .pos {
        font-family: var(--sl-font-mono);
        font-size: 1.5rem;
        color: rgb(var(--sl-color-sky-50) / 100%);
        font-weight: bold;
        opacity: 0.5;
      }
    `;
  }
}
