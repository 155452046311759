import { html, css, PropertyValues } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import SlAvatar from "@shoelace-style/shoelace/dist/components/avatar/avatar";
import { svgLogoutIcon } from "../components/svg-icons";
import {
  UserManagerSelectors,
  Connected,
  MatrixSelectors,
  RouteName,
  State,
} from "./connected";
import {
  cssAppLayout,
  SharedStyles,
  cssScrollbar,
} from "../components/sharedStyles";

@customElement("app-navbar")
export class AppNavbar extends Connected {
  @property() version: string;
  @property() os: {
    name: string;
    version: string;
    versionName: string;
  };
  @property() platform: { type: string };
  @property() browser: { name: string; version: string };

  @state() username: string;
  @state() matrixId: string;
  @state() displayName: string;
  @state() deviceId: string;
  @state() avatarUrl: string;

  @query("#startbutton") startbutton: SlAvatar;

  mapState(state: State) {
    return {
      username: UserManagerSelectors.username(state),
      matrixId: MatrixSelectors.matrixId(state),
      deviceId: MatrixSelectors.deviceId(state),
      displayName: MatrixSelectors.displayName(state),
      avatarUrl: MatrixSelectors.avatarUrl(state),
    };
  }

  updated(changedProps: PropertyValues) {
    if (changedProps.has("avatarUrl") && this.avatarUrl) {
      this.startbutton.image = this.avatarUrl;
    }
  }

  get htmlNav() {
    return html`
      <div id="start">
        <sl-dropdown>
          <sl-avatar
            id="startbutton"
            slot="trigger"
            shape="rounded"
            style="--size:2rem;"
          ></sl-avatar>

          <sl-menu class="popout_menu">
            <sl-menu-label type="text" size="small"
              >${this.matrixId}</sl-menu-label
            >
            <sl-menu-label type="text" size="small"
              >display name: ${this.displayName}</sl-menu-label
            >

            <sl-menu-label type="text" size="small"
              >device id: ${this.deviceId}</sl-menu-label
            >

            <sl-menu-divider></sl-menu-divider>

            <div style="padding-left: 2rem;">
              <sl-menu-item id="account"
                >Account
                <sl-icon slot="prefix" name="house-door-fill"></sl-icon>
              </sl-menu-item>

              <sl-menu-item id="setting"
                >Setting <sl-icon slot="prefix" name="gear"></sl-icon
              ></sl-menu-item>

              <sl-menu-item id="version"
                >Version: ${this.version}
                <sl-icon slot="prefix" name="patch-question"></sl-icon
              ></sl-menu-item>

              <sl-menu-item id="browser"
                >browser: ${this.browser.name} + ${this.browser.version}
                <sl-icon slot="prefix" name="patch-question"></sl-icon
              ></sl-menu-item>

              <sl-menu-item id="os"
                >os: ${this.os.name} + " " + ${this.os.version} + " " +
                ${this.os.versionName}
                <sl-icon slot="prefix" name="patch-question"></sl-icon
              ></sl-menu-item>

              <sl-menu-item id="platform"
                >platform: ${this.platform.type}
                <sl-icon slot="prefix" name="patch-question"></sl-icon
              ></sl-menu-item>
            </div>
          </sl-menu>
        </sl-dropdown>
      </div>

      <div class="left_navbar">${this.htmlMenuBar}</div>
    `;
  }

  get htmlMenuBar() {
    return html`
      <sl-tooltip content=${RouteName.users} placement="right-start" hoist>
        <a href="/${RouteName.users}">
          <sl-icon-button
            class="classroom_controls_button"
            library="tx-icons"
            name="users_page"
          ></sl-icon-button></a
      ></sl-tooltip>

      <sl-tooltip content=${RouteName.courses} placement="right-start" hoist>
        <a href="/${RouteName.courses}">
          <sl-icon-button
            class="classroom_controls_button"
            library="tx-icons"
            name="courses_page"
          ></sl-icon-button></a
      ></sl-tooltip>

      <sl-tooltip content=${RouteName.wordcast} placement="right-start" hoist>
        <a href="/${RouteName.wordcast}">
          <sl-icon-button
            class="classroom_controls_button"
            library="tx-icons"
            name="wordcast_page"
          ></sl-icon-button></a
      ></sl-tooltip>

      <sl-tooltip content=${RouteName.matrix} placement="right-start" hoist>
        <a href="/${RouteName.matrix}/${this.username}">
          <sl-icon-button
            class="classroom_controls_button"
            library="tx-icons"
            name="textchat_switch"
          ></sl-icon-button></a
      ></sl-tooltip>

      <sl-tooltip content=${RouteName.dashboard} placement="right-start" hoist>
        <a href="/${RouteName.dashboard}/${this.username}">
          <sl-icon-button
            class="classroom_controls_button"
            library="tx-icons"
            name="dashboard_page"
          ></sl-icon-button></a
      ></sl-tooltip>

      <sl-tooltip content=${RouteName.logout} placement="right-start" hoist>
        <a href="/${RouteName.logout}">
          <sl-icon-button
            class="classroom_controls_button"
            library="tx-icons"
            name="logout"
          ></sl-icon-button></a
      ></sl-tooltip>
    `;
  }

  render() {
    return html` ${this.username ? html` ${this.htmlNav} ` : html``} `;
  }

  static get styles() {
    const cssNav = css`
      #start {
        z-index: 99999;
        position: fixed;
        bottom: 0.25rem;
        left: 0;
        font-size: 0.5rem;
        margin: 0;
        padding: 0;
      }

      #startbutton {
        z-index: 99999;
        cursor: pointer;
        --size: 1.5rem;
      }

      .left_navbar {
        z-index: 9999;
        position: fixed;
        left: 0;
        bottom: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: rgb(var(--sl-color-neutral-500) / 5%);
        margin: 0;
        padding: 0rem;
      }
    `;

    return [SharedStyles, cssScrollbar, cssAppLayout, cssNav, css``];
  }
}
