import { html, css, PropertyValues } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { Recorder } from "vmsg";
import {
  UserManagerSelectors,
  // ClassroomSelectors,
  MatrixSelectors,
  Connected,
  dispatch,
  State,
} from "./connected";
import {
  IMatrixRoomlet,
  IRoomMessage,
  MatrixClient,
} from "../lib/matrix/MatrixClient";
import {
  cssAttachments,
  cssChatbox,
  cssVoiceRecorder,
  SharedStyles,
} from "../components/sharedStyles";
import { IMatrixRoomMember } from "../state/models/matrix";
import {
  copyrighter,
  LEFT_PANEL_WIDTH,
  RIGHT_PANEL_WIDTH,
  ServiceUrls,
} from "../config";
import { Attachment } from "@github/file-attachment-element";
import "@github/file-attachment-element";
import { cssAppLayout, cssScrollbar } from "../components/sharedStyles";
import {
  disableContextMenu,
  preventRefresh,
  StayCurrentPage,
} from "../lib/utils/browser";
import { roomMessageType } from "../lib/matrix/models/events/MessageEvent";
import log from "loglevel";
import { getAspectRatio } from "../lib/utils/aspectratio";
import "../components/user-form";
import "../components/request-media-devices";
import { SlAvatar } from "@shoelace-style/shoelace";
import { RequestMediaDevices } from "../components/request-media-devices";
import { name } from "../../package.json";

const enum chatControlsButton {
  // sendMessage = "cloud-arrow-up-fill",
  textmode = "type",
  mathmode = "calculator",
  emoji = "emoji-sunglasses-fill",
  attach = "paperclip",
  files = "collection-fill",
  voicecall = "telephone-outbound-fill",
  videocall = "camera-reels-fill",
  sendfiles = "send_attachments_button",
  cancelsendfiles = "cancel_attachments_button",
  startrecording = "mic-fill",
  stoprecording = "stop-circle",
  cancelvoicemail = "x-square",
  sendvoicemail = "envelope-fill",
}

const enum LayoutState {
  mainboard_only,
  left_main,
  right_main,
}

@customElement("matrix-page")
export class MatrixPage extends Connected {
  @state() isHost: boolean;
  @state() mxSession: MatrixClient;
  @state() avatarUrl: string;
  @state() displayName: string;
  @state() matrixId: string;
  @state() deviceId: string;
  @state() joinedRooms: any[];
  @state() currentRoom: IMatrixRoomlet;
  @state() currentMembers: IMatrixRoomMember[];
  @state() roomMessages: IRoomMessage[];

  // chatroom
  @state() isTextMode: boolean = true;
  @state() recorder = new Recorder({ wasmURL: ServiceUrls.WASM_URL });
  @state() isLoading: boolean = false;
  @state() isRecording: boolean = false;
  @state() recording: string;
  @state() voicemailBlob: Blob = new Blob();
  @state() selectedAttachments: Attachment[] | null;

  // mainboard
  @state() isMainboardOnly: boolean = false;
  @state() showRightPanel: boolean = true;
  @state() showLeftPanel: boolean = true;

  @state() aspectRatio: string;
  @state() iframed_source_url: string = "";

  private readonly iframed_name: string = "iframed_drawer";

  // classroom
  @state() roomName: string;
  @state() roomId: string;
  @state() roomNumber: number;

  @query("#requestMediaDevices") requestMediaDevices: RequestMediaDevices;

  @query("#dashboard_container") dashboard_container: HTMLDivElement;
  @query("sl-resize-observer") resizeObserver: any; // SlResizeObserver;

  // left panel
  @query("#left_panel") left_panel: HTMLDivElement;
  @query("#tx_chat_window") elChatWindow: HTMLDivElement;

  // middle panel
  @query("#mainboard") mainboard: HTMLDivElement;
  @query("#mainboard_iframe") mainboard_iframe: HTMLIFrameElement;
  @query("#iframed_content_view") iframed_content_view: HTMLDivElement;

  // right panel
  @query("#right_panel") right_panel: HTMLDivElement;
  // @query("#roomvideo") roomvideo: VideoChatView;
  @query("#rooms_drawer") rooms_drawer: any;
  @query("#rooms_details") rooms_details: any;
  @query("#details_members") detailsMembers: any;
  @query("#calendar_details") calendar_details: any;
  @query("#task_details") task_details: any;
  @query("#resources_details") resources_details: any;

  // footer
  @query("#room_avatar") room_avatar: SlAvatar;
  @query("#inputcontrols") elInput: HTMLDivElement;
  @query("#textinput") elTextInput: any;
  // @query("#mathinput") elMathInput: MathfieldElement;
  @query("#audio_visual_media_group") elAudioVisualGroup: any;
  @query("#voicemailwriter") elVoiceMailWriter: HTMLDivElement;
  @query("#voicemail_controls") elVoiceMailControls: any;
  @query("#recording_in_progress") elRecording: HTMLDivElement;
  @query("#voicemail_player") voicemail_player: HTMLAudioElement;
  @query("#attachments_preview") elAttachments: HTMLPreElement;
  @query("#file_drawer") file_drawer: any;
  @query("#file_drawer_container") elFileDrawerContainer: HTMLDivElement;

  private _currentRoomMessages: IRoomMessage[];
  private _right_panel_width = window.innerWidth * RIGHT_PANEL_WIDTH; //15% of vw
  private _left_panel_width = window.innerWidth * LEFT_PANEL_WIDTH; //20% of vw

  mapState(state: State) {
    return {
      isHost: UserManagerSelectors.isHost(state),
      matrixId: MatrixSelectors.matrixId(state),
      deviceId: MatrixSelectors.deviceId(state),
      displayName: MatrixSelectors.displayName(state),
      avatarUrl: MatrixSelectors.avatarUrl(state),
      mxSession: MatrixSelectors.session(state),
      joinedRooms: MatrixSelectors.joinedRooms(state),
      currentRoom: MatrixSelectors.currentRoom(state),
      roomMessages: MatrixSelectors.roomMessages(state),
      currentMembers: MatrixSelectors.currentMembers(state),
    };
  }

  firstUpdated() {
    StayCurrentPage();
    preventRefresh();
    disableContextMenu();
    this.hideVoiceMailWriter();
    // this.iframed_content_view.style.display = "block";
    this.resizeObserver.addEventListener("sl-resize", (event: CustomEvent) => {
      let containerWidth = Math.round(this.mainboard.clientWidth);
      let containerHeight = Math.round(this.mainboard.clientHeight);
      this.aspectRatio = getAspectRatio(containerWidth, containerHeight);
    });

    this.file_drawer.addEventListener("sl-request-close", (event) =>
      event.preventDefault()
    );

    window.addEventListener("beforeunload", (e) => {
      // if (this.isLive) {
      //   // dispatch.classroom.close(); // send closing event to the room to update attendees
      //   dispatch.socket.disconnectSocket();
      //   sessionStorage.clear();
      // }

      // the absence of a returnValue property on the event will guarantee the browser unload happens
      delete e["returnValue"];
    });

    this.rooms_drawer.addEventListener("sl-show", (event) => {
      [...this.rooms_drawer.querySelectorAll("sl-details")].map(
        (details) => (details.open = event.target === details)
      );
    });
  }

  async updated(changedProps: PropertyValues) {
    if (
      changedProps.has("currentRoom") &&
      this.currentRoom &&
      this.currentRoom.roomAvatar
    ) {
      this.room_avatar.image = this.currentRoom.roomAvatar;
    }

    this.elAudioVisualGroup.style.display = this.isHost ? "block" : "none";
    // this.iframed_content_view.style.display = "block";

    if (changedProps.has("roomMessages")) {
      if (this.elChatWindow) {
        this.elChatWindow.scrollTop = this.elChatWindow.scrollHeight;
      }
    }
  }

  hideVoiceMailWriter() {
    this.elVoiceMailWriter.style.display = "none";
    this.elVoiceMailControls.style.display = "none";
    this.elRecording.style.display = "none";
    this.voicemail_player.style.display = "none";
  }

  onRoomSelected(e: CustomEvent) {
    dispatch.matrix.loadCurrentRoom({ roomId: e.detail["item"].value });
    this.rooms_details.hide();
    this.detailsMembers.show();

    // this.iframed_content_view.style.display = "none";
  }

  onMemberSelected(e: CustomEvent) {
    // console.log("onMemberSelected", e.detail.item);
  }

  async checkMediaPermissions(roomId: string) {
    // TODO: listen to if there is a change on the audio/video piece?
    if (await this.requestMediaDevices.checkPermission()) {
      // dispatch.classroom.joinClassroom(roomId);
    }
  }

  onClick(e: any) {
    if (!e.target.name) return;
    switch (e.target.name) {
      case chatControlsButton.attach:
        this.handleFileAttach();
        break;
      case chatControlsButton.cancelsendfiles:
        this.handleCancelAttachments();
        break;
      case chatControlsButton.sendfiles:
        this.handleSendAttachments();
        break;
      case chatControlsButton.startrecording:
        this.handleVoiceMail();
        break;
      case chatControlsButton.stoprecording:
        this.handleVoiceMail();
        break;
      case chatControlsButton.cancelvoicemail:
        this.handleCancelVoiceMail();
        break;
      case chatControlsButton.sendvoicemail:
        this.handleSendVoiceMail();
        break;

      case chatControlsButton.emoji:
        this.handleEmoji();
        break;
      case chatControlsButton.voicecall:
        this.handleVoiceCall();
        break;
      case chatControlsButton.videocall:
        this.handleVideoCall();
        break;
      default:
        log.error("unhandled button click");
    }
  }

  onFileAttachmentAccepted(e: CustomEvent) {
    this.elAttachments.style.display = "inline-block";
    const { attachments } = e.detail;
    this.elAttachments.textContent = attachments
      .map((a: Attachment) => a.file.name)
      .join("\n");

    this.selectedAttachments = attachments;
  }

  handleSendAttachments() {
    dispatch.matrix.sendAttachments({
      roomId: this.currentRoom.roomId,
      attachments: this.selectedAttachments,
    });

    this.resetAttachementDrawer();
  }

  handleCancelAttachments() {
    this.resetAttachementDrawer();
  }

  resetAttachementDrawer() {
    this.selectedAttachments = null;
    this.elAttachments.textContent = "";
    this.elAttachments.style.display = "none";
    this.file_drawer.hide();
    this.elFileDrawerContainer.style.display = "none";
  }

  handleFileAttach() {
    this.elFileDrawerContainer.style.display = "flex";
    this.file_drawer.show();
  }

  handleSendMessage() {
    let roomId = this.currentRoom.roomId;
    if (this.isTextMode) {
      if (!this.elTextInput.value.trim().length) {
        this.elTextInput.value = "";
        return;
      }
      dispatch.matrix.sendTextMessage({
        roomId,
        message: this.elTextInput.value,
      });
      this.elTextInput.value = "";
    } else {
      // if (!this.elMathInput.getValue("latex-expanded")) return;
      // dispatch.matrix.sendMathMessage({
      //   roomId,
      //   data: this.elMathInput.getValue("latex-expanded"),
      // });
      // this.elMathInput.value = "";
    }
  }

  handleSendVoiceMail() {
    dispatch.matrix.sendVoiceMail({
      roomId: this.currentRoom.roomId,
      audioblob: this.voicemailBlob,
    });

    this.voicemailBlob = new Blob();
    this.recording = "";
    this.hideVoiceMailWriter();
  }

  handleCancelVoiceMail() {
    this.voicemailBlob = new Blob();
    this.recording = "";
    this.hideVoiceMailWriter();
  }

  async handleVoiceMail() {
    this.elVoiceMailWriter.style.display = "flex";

    this.isLoading = true;
    if (this.isRecording) {
      this.voicemailBlob = await this.recorder.stopRecording();
      this.recording = URL.createObjectURL(this.voicemailBlob);
      this.isLoading = false;
      this.isRecording = false;
    } else {
      try {
        await this.recorder.initAudio();
        await this.recorder.initWorker();
        this.recorder.startRecording();
        this.isLoading = false;
        this.isRecording = true;
      } catch (e) {
        console.error(e);
        this.isLoading = false;
        return;
      }
    }

    this.elRecording.style.display = this.isRecording ? "inline-block" : "none";
    this.elVoiceMailControls.style.display = this.isRecording ? "none" : "flex";
    this.voicemail_player.style.display = this.isRecording ? "none" : "flex";
  }

  handleVoiceCall() {}

  handleVideoCall() {}

  async handleEmoji() {}

  onKeyUp(e: any) {
    // If Enter key pressed, fire 'enter-pressed'
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      if (this.isTextMode) this.handleSendMessage();
    }
  }

  get htmlRightPanelDrawer() {
    return html`
      <div id="rooms_drawer">
        <sl-details class="sl_details" id="rooms_details" summary="Rooms" open>
          <sl-menu id="menu_rooms" @sl-select=${this.onRoomSelected}>
            ${this.joinedRooms.map(
              (room: IMatrixRoomlet) =>
                html`
                  <div class="tx_avatar__header">
                    <sl-menu-item
                      class="room_header"
                      value=${room.roomId}
                      @click=${() => (this.currentRoom = room)}
                    >
                      <span>
                        <sl-avatar
                          class="small_avatar"
                          shape="rounded"
                          image=${room.roomAvatar}
                        ></sl-avatar>
                        ${room.roomName}</span
                      >
                    </sl-menu-item>
                  </div>
                `
            )}
          </sl-menu>
        </sl-details>

        <sl-details id="details_members" class="sl_details" summary="Members">
          <sl-menu id="menu_members" @sl-select=${this.onMemberSelected}>
            ${this.htmlMembers}
          </sl-menu></sl-details
        >
      </div>
    `;
  }

  get htmlMembers() {
    return html` ${this.currentMembers.map(
      (m) =>
        html` <sl-menu-item value=${m.matrixId}>
          <div class="profile_panel">
            <sl-avatar
              class="small_avatar"
              shape="circle"
              image="${m.avatarUrl}"
            ></sl-avatar>
            <sl-badge type="primary" pulse>${m.displayName}</sl-badge>
          </div></sl-menu-item
        >`
    )}`;
  }

  get htmlRenderMessageContent() {
    if (!this.roomMessages || !this.currentRoom) return;

    this._currentRoomMessages = this.roomMessages.filter(
      (x) => x.roomId === this.currentRoom.roomId
    );

    return html` <div id="tx_chat_window">
      ${this._currentRoomMessages.map(
        (x) => html`
          <div class="msgbox" id=${this._currentRoomMessages.indexOf(x)}>
            <div class="msgbox_header">
              <div class="sender_profile">
                <sl-avatar
                  class="sender"
                  shape="circle"
                  image=${x.avatarUrl}
                ></sl-avatar>
                <sl-badge variant="primary" pill>${x.displayName}<sl-badge>
              </div>
              <div>
                <span class="timestamp">
                  <sl-relative-time date=${new Date(x.time).toISOString()}
                    >${new Date(x.time).toISOString()}
                  </sl-relative-time></span
                >
                <span class="timestamp">
                  ${new Date(x.time).toLocaleString()}</span
                >
              </div>
            </div>
            ${this.htmlMessageBody(x.message)}
          </div>
        `
      )}
    </div>`;
  }

  htmlMessageBody(message: any) {
    let r = html``;
    switch (message.type) {
      case roomMessageType.m_math:
        r = html`<div class="msg_text">
          ${message.text}
          <math-field read-only>${message.math_body}</math-field>
        </div>`;
        break;
      case roomMessageType.m_text:
        r = html`<div class="msg_text">${message.text}</div>`;
        break;
      case roomMessageType.m_file:
        if (message.info!.mimetype === "application/pdf") {
          r = html`
            <a href=${message.url} target=${this.iframed_name}
              >${message.text}
            </a>
          `;
          // this.iframed_content_view.style.display = "block";
        } else {
          r = html` <div class="msg_text">
            <a href=${message.url} type=${message.info!.mimetype} download
              >${message.text}</a
            >
          </div>`;
        }
        break;
      case roomMessageType.m_image:
        r = html` <div class="msg_text">
          ${message.text}
          <sl-responsive-media
            aspect-ratio=${getAspectRatio(message.info.w, message.info.h)}
          >
            <img src=${message.url} alt=${message.text} loading="lazy"
          /></sl-responsive-media>
        </div>`;
        break;
      case roomMessageType.m_audio:
        let source = message.url;
        r = html`<div class="msg_text">
          ${message.text}
          <audio
            class="tx_msg_audio_player"
            controls
            src=${source}
            type=${message.info?.mimetype}
          ></audio>
        </div> `;
        break;
      case roomMessageType.m_video:
        r = html`<div class="msg_text">
          ${message.text}
          <sl-responsive-media
            aspect-ratio=${getAspectRatio(message.info.w, message.info.h)}
          >
            <video controls playsinline>
              <source
                src=${message.url}
                type=${message.info!.mimetype}
                loading="lazy"
              /></video
          ></sl-responsive-media>
        </div> `;
        break;
      default:
        return html``;
    }
    return r;
  }

  get htmlFileDrawer() {
    return html` <div id="file_drawer_container">
      <sl-drawer
        id="file_drawer"
        label="attach and upload files"
        placement="end"
        contained
        no-header
        style="--size:15%"
      >
        <file-attachment
          class="tx_align_horizontal fileupload"
          input="file"
          @file-attachment-accepted=${this.onFileAttachmentAccepted}
        >
          <div class="file_drawer_content">
            <div class="select_files">
              <label for="file" class="fileupload">
                <sl-icon-button name="folder-symlink-fill"></sl-icon-button>
                select files
              </label>
              <input type="file" id="file" multiple />
            </div>

            <sl-button
              name=${chatControlsButton.sendfiles}
              slot="footer"
              type="text"
              size="small"
              pill
              @click=${this.onClick}
              ><sl-icon slot="prefix" name="cloud-upload-fill"></sl-icon
              >SEND</sl-button
            >
            <sl-button
              name=${chatControlsButton.cancelsendfiles}
              type="text"
              size="small"
              pill
              @click=${this.onClick}
              ><sl-icon slot="prefix" name="x-square-fill"></sl-icon
              >Cancel</sl-button
            >
          </div>
        </file-attachment>
        <pre id="attachments_preview"></pre>
      </sl-drawer>
    </div>`;
  }

  get htmlVoiceMail() {
    return html`
      <sl-badge id="recording_in_progress" type="primary" pulse
        >Recording</sl-badge
      >
      <div id="voicemailwriter" class="tx_align_horizontal">
        <audio
          id="voicemail_player"
          src=${this.recording}
          autoplay
          controls
        ></audio>

        <sl-button-group id="voicemail_controls" class="tx_align_horizontal">
          <sl-tooltip content="send voice message">
            <sl-icon-button
              name=${chatControlsButton.sendvoicemail}
              @click=${this.onClick}
            ></sl-icon-button>
          </sl-tooltip>
          <sl-tooltip content="cancel voice message">
            <sl-icon-button
              name=${chatControlsButton.cancelvoicemail}
              @click=${this.onClick}
            ></sl-icon-button>
          </sl-tooltip>
        </sl-button-group>
      </div>
    `;
  }

  get htmlFooter() {
    return html`
      <div id="footer_container" class="footer_avatar_header">
        <sl-dropdown>
          <sl-avatar
            id="room_avatar"
            slot="trigger"
            class="mid_avatar"
            shape="circle"
          ></sl-avatar>

          <sl-menu class="popout_menu">
            <sl-menu-item class="popout_menu_item">
              ${!this.currentRoom
                ? ""
                : this.currentRoom.roomName}</sl-menu-item
            >
            <sl-menu-item class="popout_menu_item">
              ${!this.currentRoom
                ? ""
                : this.currentRoom.roomTopic}</sl-menu-item
            >
          </sl-menu>

          <sl-menu class="popout_menu">${this.htmlMembers}</sl-menu>
        </sl-dropdown>
      </div>

      <div id="inputcontrols" @keyup="${this.onKeyUp}">
        <sl-textarea
          id="textinput"
          rows="1"
          size="small"
          resize="none"
          maxlength="1000"
          autofocus="true"
          spellcheck="false"
          autocapitalize="off"
          placeholder="write your message here..."
        >
        </sl-textarea>

        <div id="controls">
          <sl-icon-button
            name=${chatControlsButton.attach}
            @click=${this.onClick}
          ></sl-icon-button>

          <sl-icon-button
            name=${this.isRecording
              ? chatControlsButton.stoprecording
              : chatControlsButton.startrecording}
            @click=${this.onClick}
          ></sl-icon-button>

          <sl-button-group id="audio_visual_media_group">
            <sl-icon-button
              name=${chatControlsButton.emoji}
              @click=${this.onClick}
            ></sl-icon-button>
            <sl-icon-button
              name=${chatControlsButton.voicecall}
              @click=${this.onClick}
            ></sl-icon-button>
            <sl-icon-button
              name=${chatControlsButton.videocall}
              @click=${this.onClick}
            ></sl-icon-button
          ></sl-button-group>
        </div>

        ${this.htmlVoiceMail}
      </div>
    `;
  }

  get htmlLeftToolbar() {
    return html`
      <div id="app_ui_toolbar">
        <sl-icon-button
          id="btn_toggle_right_panel"
          name=${this.showRightPanel
            ? "arrow-right-square"
            : "layout-three-columns"}
          @click=${this.toggleRightPanel}
        ></sl-icon-button>

        <sl-icon-button
          id="btn_toggle_right_panel"
          name=${this.showRightPanel
            ? "arrow-left-square"
            : "layout-three-columns"}
          @click=${this.toggleLeftPanel}
        ></sl-icon-button>
      </div>
    `;
  }

  toggleRightPanel() {
    this.showRightPanel = !this.showRightPanel;

    if (!this.showRightPanel) {
      this.mainboard.style.width =
        this.mainboard.offsetWidth + this._right_panel_width + "px";
      this.right_panel.style.width = "0";
    } else {
      this.mainboard.style.width =
        this.mainboard.offsetWidth - this._right_panel_width + "px";
      this.right_panel.style.width = this._right_panel_width + "px";
    }
  }

  toggleLeftPanel() {
    this.showLeftPanel = !this.showLeftPanel;

    if (!this.showLeftPanel) {
      this.left_panel.style.width = "0";
      this.mainboard.style.left = -this._left_panel_width + "px";
      this.mainboard.style.width =
        this.mainboard.offsetWidth + this._left_panel_width + "px";
    } else {
      this.left_panel.style.width = this._left_panel_width + "px";
      this.mainboard.style.left = "0px";
      this.mainboard.style.width =
        this.mainboard.offsetWidth - this._left_panel_width + "px";
    }
  }

  render() {
    return html`<div class="container">
      <div class="backText">
        ${!this.currentRoom ? "" : this.currentRoom.roomName}
      </div>

      <welcome-screen class="centered"></welcome-screen>

      <request-media-devices
        id="requestMediaDevices"
        appName=${name}
      ></request-media-devices>

      <sl-resize-observer>
        <div class="grid_container" id="dashboard_container">
          <div class="left_toolbar">${this.htmlLeftToolbar}</div>

          <div class="left_panel_container" id="left_panel">
            ${this.htmlRenderMessageContent}
          </div>

          <!-- <div class="resizer_left" id="dragLeft"></div> -->

          <div class="mainboard_container" id="mainboard">
            <div class="board_content_container">
              <div id="iframed_content_view">
                <sl-responsive-media
                  id="iframe_responsive"
                  aspect-ratio=${this.aspectRatio}
                >
                  <iframe
                    id="mainboard_iframe"
                    name=${this.iframed_name}
                    class="iframed"
                    src=${this.iframed_source_url}
                    width="100%"
                    height="100%"
                    frameborder="0"
                    loading="lazy"
                    allowfullscreen
                  ></iframe
                ></sl-responsive-media>
              </div>
            </div>
          </div>

          <!-- <div class="resizer_right" id="dragRight"></div> -->

          <div id="right_panel" class="right_panel_container">
            ${this.htmlRightPanelDrawer}
            <video-chat-view
              id="roomvideo"
              video_panel_width=${this._right_panel_width}
            ></video-chat-view>
            ${this.htmlFileDrawer}
          </div>

          <div class="footer">${this.htmlFooter}</div>

          <div class="footsy footer__copyright">
            <p>&copy; ${copyrighter}</p>
          </div>
        </div>
      </sl-resize-observer>
    </div> `;
  }

  static get styles() {
    return [
      SharedStyles,
      cssScrollbar,
      cssAppLayout,
      cssChatbox,
      cssAttachments,
      cssVoiceRecorder,
      css`
        sl-divider::part(base) {
          background: transparent;
        }

        audio {
          background: transparent;
        }

        #drawer_container {
          position: absolute;
          top: 0;
          left: 2rem;
          right: 0;
          bottom: 2.5rem;
          margin: 0;
          padding: 0;
          width: calc(100vw - 2.5rem - 15vw);
          height: calc(100vh -2.5rem);
        }

        .smartboard {
          width: 100%;
          height: 100%;
          z-index: 3;
        }

        .file_drawer_content {
          display: flex;
          flex-direction: column;
          font-size: 0.5rem;
        }

        #room_avatar {
          cursor: pointer;
        }

        #iframed_content_view {
          display: block;
        }
      `,
    ];
  }
}
