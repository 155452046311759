import { html, css, PropertyValues } from "lit";
import { customElement, state } from "lit/decorators.js";
import { Connected, State, UserManagerSelectors } from "./connected";
import {
  cssAppLayout,
  cssMainGrid,
  cssScrollbar,
  SharedStyles,
} from "../components/sharedStyles";

@customElement("dash-board")
export class DashBoard extends Connected {
  @state() username: string;

  mapState(state: State) {
    return {
      username: UserManagerSelectors.username(state),
    };
  }

  // updated(changedProps: PropertyValues) {
  //   if (changedProps.has("username") && !!this.username) {
  //   }
  // }

  render() {
    return html`
      <div id="athome">
        <h2>DASHBOARD</h2>
      </div>
    `;
  }

  static get styles() {
    return [
      SharedStyles,
      cssScrollbar,
      cssAppLayout,
      cssMainGrid,
      css`
        #athome {
          display: flex;
          justify-content: center;
        }
      `,
    ];
  }
}
