import { css, html, LitElement } from "lit";
import { customElement, query } from "lit/decorators.js";
import { serialize } from "@shoelace-style/shoelace/dist/utilities/form.js";

@customElement("login-box")
export class LoginBox extends LitElement {
  @query("form") loginform: HTMLFormElement;

  onSubmit(e: any) {
    e.preventDefault();
    const detail = serialize(this.loginform);
    try {
      this.dispatchEvent(
        new CustomEvent("login_with_password", {
          bubbles: true,
          composed: true,
          detail,
        })
      );
    } catch (err) {
      console.log(err); // TODO
    }
  }

  render() {
    return html` <form class="box" @submit=${this.onSubmit}>
      <sl-input
        type="text"
        placeholder="username"
        name="username"
        required
        draggable="false"
        size="small"
        pill
        autocapitalize="off"
        spellcheck="false"
        autofocus="true"
        clearable="true"
      ></sl-input>

      <sl-input
        type="password"
        placeholder="password"
        name="password"
        required
        draggable="false"
        size="small"
        pill
        toggle-password
        autocapitalize="off"
        spellcheck="false"
        autofocus="true"
      ></sl-input>

      <button class="btn" type="submit">
        <img
          class="control-item"
          width="80px"
          src="assets/login_menu/login_submit_button.png"
          id="login_submit_button"
          draggable="false"
        />
      </button>
    </form>`;
  }

  static get styles() {
    return [
      css`
        .box {
          width: 250px;
          padding: 40px;
          position: absolute;
          top: 25%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
        }

        sl-input::part(base) {
          margin: 0.25rem;
          border: 1px solid rgba(225, 239, 143, 0.15);
        }

        .btn {
          background: transparent;
          cursor: pointer;
          border-radius: 10%;
          border: none; //1px solid rgba(195, 247, 7, 0.5);
        }
        .btn:hover {
          box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.23),
            0 17px 20px 0 rgba(0, 0, 0, 0.19);
          background-color: rgba(225, 239, 143, 0.75);
          color: white;
        }
      `,
    ];
  }
}
