import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path.js";
import { registerIconLibrary } from "@shoelace-style/shoelace/dist/utilities/icon-library";

import "@shoelace-style/shoelace/dist/components/alert/alert";
import "@shoelace-style/shoelace/dist/components/alert/alert.styles";
import "@shoelace-style/shoelace/dist/components/avatar/avatar";
import "@shoelace-style/shoelace/dist/components/avatar/avatar.styles";
import "@shoelace-style/shoelace/dist/components/button/button";
import "@shoelace-style/shoelace/dist/components/button/button.styles";
import "@shoelace-style/shoelace/dist/components/badge/badge";
import "@shoelace-style/shoelace/dist/components/badge/badge.styles";
import "@shoelace-style/shoelace/dist/components/button-group/button-group";
import "@shoelace-style/shoelace/dist/components/button-group/button-group.styles";
import "@shoelace-style/shoelace/dist/components/details/details";
import "@shoelace-style/shoelace/dist/components/details/details.styles";
import "@shoelace-style/shoelace/dist/components/dialog/dialog";
import "@shoelace-style/shoelace/dist/components/dialog/dialog.styles";
import "@shoelace-style/shoelace/dist/components/divider/divider";
import "@shoelace-style/shoelace/dist/components/divider/divider.styles";
import "@shoelace-style/shoelace/dist/components/drawer/drawer";
import "@shoelace-style/shoelace/dist/components/drawer/drawer.styles";
import "@shoelace-style/shoelace/dist/components/dropdown/dropdown";
import "@shoelace-style/shoelace/dist/components/dropdown/dropdown.styles";
import "@shoelace-style/shoelace/dist/components/icon/icon";
import "@shoelace-style/shoelace/dist/components/icon/icon.styles";
import "@shoelace-style/shoelace/dist/components/icon-button/icon-button";
import "@shoelace-style/shoelace/dist/components/icon-button/icon-button.styles";
import "@shoelace-style/shoelace/dist/components/input/input";
import "@shoelace-style/shoelace/dist/components/input/input.styles";
import "@shoelace-style/shoelace/dist/components/menu/menu";
import "@shoelace-style/shoelace/dist/components/menu/menu.styles";
import "@shoelace-style/shoelace/dist/components/menu-item/menu-item";
import "@shoelace-style/shoelace/dist/components/menu-item/menu-item.styles";
import "@shoelace-style/shoelace/dist/components/menu-label/menu-label";
import "@shoelace-style/shoelace/dist/components/menu-label/menu-label.styles";
import "@shoelace-style/shoelace/dist/components/progress-bar/progress-bar";
import "@shoelace-style/shoelace/dist/components/progress-bar/progress-bar.styles";
import "@shoelace-style/shoelace/dist/components/progress-ring/progress-ring";
import "@shoelace-style/shoelace/dist/components/progress-ring/progress-ring.styles";
import "@shoelace-style/shoelace/dist/components/radio-group/radio-group";
import "@shoelace-style/shoelace/dist/components/radio-group/radio-group.styles";
import "@shoelace-style/shoelace/dist/components/radio/radio";
import "@shoelace-style/shoelace/dist/components/radio/radio.styles";
import "@shoelace-style/shoelace/dist/components/range/range";
import "@shoelace-style/shoelace/dist/components/range/range.styles";
import "@shoelace-style/shoelace/dist/components/relative-time/relative-time";
import "@shoelace-style/shoelace/dist/components/relative-time/relative-time";
import "@shoelace-style/shoelace/dist/components/resize-observer/resize-observer";
import "@shoelace-style/shoelace/dist/components/resize-observer/resize-observer.styles";
import "@shoelace-style/shoelace/dist/components/responsive-media/responsive-media";
import "@shoelace-style/shoelace/dist/components/responsive-media/responsive-media.styles";
import "@shoelace-style/shoelace/dist/components/select/select";
import "@shoelace-style/shoelace/dist/components/select/select.styles";
import "@shoelace-style/shoelace/dist/components/spinner/spinner";
import "@shoelace-style/shoelace/dist/components/spinner/spinner.styles";
import "@shoelace-style/shoelace/dist/components/textarea/textarea";
import "@shoelace-style/shoelace/dist/components/textarea/textarea.styles";
import "@shoelace-style/shoelace/dist/components/tooltip/tooltip";
import "@shoelace-style/shoelace/dist/components/tooltip/tooltip.styles";

import "./components/pwa-update";
import "./pages/app-router";
import "./pages/app-navbar";
import { version } from "../package.json";
import Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);

@customElement("app-index")
export class AppIndex extends LitElement {
  getVersion() {
    return "[VI]{version} - {date}[/VI]";
  }

  firstUpdated() {
    setBasePath("assets/shoelace");
    registerIconLibrary("tx-icons", {
      resolver: (name: string) => `assets/svgicons/${name}.svg`,
      mutator: (svg) => svg.setAttribute("fill", "currentColor"),
    });
  }

  render() {
    return html`
      <app-navbar
        version=${version}
        .browser=${browser.getBrowser()}
        .platform=${browser.getPlatform()}
        .os=${browser.getOS()}
      ></app-navbar>
      <app-router></app-router>
      <pwa-update id="tutorwix"></pwa-update>
    `;
  }
}
