import createMatcher from "@captaincodeman/router";
import { routingPlugin } from "@captaincodeman/rdx";
import * as models from "./models";

export const enum RouteName {
  home = "home",
  matrix = "matrix",
  dashboard = "dashboard",
  users = "users",
  courses = "courses",
  courseDetail = "courseDetail",
  mediaDetail = "mediaDetail",
  logout = "logout",
  notfound = "notfound",
  wordcast = "wordcast",
  wordcastDetail = "wordcastDetail",
}

const routes = {
  "/": RouteName.home,
  "/matrix/:username": RouteName.matrix,
  "/dashboard/:username": RouteName.dashboard,
  "/users": RouteName.users,
  "/courses": RouteName.courses,
  "/courses/:courseId": RouteName.courseDetail,
  "/wordcast": RouteName.wordcast,
  "/wordcast/:wordId": RouteName.wordcastDetail,
  "/mediaDetail/:mediaId": RouteName.mediaDetail,
  "/logout": RouteName.logout,
  "/*": RouteName.notfound,
};

const matcher = createMatcher(routes);

export const routing = routingPlugin(matcher);
export const config = { models, plugins: { routing } };
