export const enum ServiceUrls {
  APP_ORIGIN_HTTP_URL = "https://tutorwix.net",
  // MX_HOMESERVER_BASE_URL = "https://tutorwix.cloud/",

  API_BASE_URL = "https://jadebender.net",
  UWS_URL = "wss://jadebender.net",
  MX_HOMESERVER_BASE_URL = "https://tutorwix.org/",

  WASM_URL = "/assets/wasm/vmsg.wasm",
}

export const enum ApiEndpoints {
  getMediaDirectories = "getMediaDirectories",
  getCourseList = "getCourseList",
  getMediaList = "getMediaList",
  getNewlyAddedMediaList = "getNewlyAddedMediaList",

  getPdfList = "getPdfList",
  getQuizList = "getQuizList",
  getActivatedMedia = "getActivatedMedia",
  addCourse = "addCourse",
  addWordcast = "addWordcast",
  addUpdateMediaRecord = "addUpdateMediaRecord",

  getClozeData = "getClozeData",
  getQuizData = "getQuizData",
  janusParams = "janusParams",
  getProseEditorSnapshot = "getProseEditorSnapshot",

  register = "register",
  signin = "signin",
  updateUserAccount = "updateUserAccount",
  getUserDetail = "getUserDetail",
  getUsers = "getUsers",
}

export const enum AppConfig {
  APP_NAME = "tutorwix.editor",
  ACCESS_TOKEN = "tutorwix_access_token",
  DEVICE_ID = "tutorwix_device_id",
  USER_ID = "tutorwix_user_id",
  BASE_URL = "tutorwix_hs_base_url",
}

export enum EventName {
  // classroom_joined = "classroom_joined",
  // classroom_leave = "classroom_leave",
  // classroom_event = "classroom_event",
  prose_editor_updated = "prose_editor_updated",
  wsrpc = "wsrpc",
  wsrpc_response = "wsrpc_response",
  disconnect = "disconnect",
}

export const copyrighter = `Handcrafted with ❤ by Laoben. All rights reserved.`;
export const API_REQUEST_TIMEOUT = 800000;
export const WEBSOCKET_CONNECTION_TIMEOUT = 5000;
export const CHAT_VIDEO_ASPECT_RATIO = 0.75;
export const RIGHT_PANEL_WIDTH = 0.15;
export const LEFT_PANEL_WIDTH = 0.2;
