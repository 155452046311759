import { serialize } from "@shoelace-style/shoelace/dist/utilities/form";
import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { IWordcast } from "../state/models/resource";
import { cssFormStyle, cssScrollbar } from "./sharedStyles";

@customElement("wordcast-form")
export class WordcastForm extends LitElement {
  @property() wordcast: IWordcast;
  @property() isEdit: boolean;

  @query("form") frm: HTMLFormElement;
  @query("#wordId") wordId: HTMLInputElement;

  onSubmit(e) {
    e.preventDefault();
    const raw = serialize(this.frm);
    let entries = Object.entries(raw).filter(([key]) => raw[key] !== ""); // filter out empty input
    let detail = Object.fromEntries(entries);
    const evtName = this.isEdit ? "edit_wordcast" : "add_wordcast";
    try {
      this.dispatchEvent(
        new CustomEvent(evtName, {
          bubbles: true,
          composed: true,
          detail,
        })
      );
    } catch (err) {
      console.log(err); // TODO
    }
  }

  get htmlEditWordcast() {
    return html`
      <sl-input
        id="header"
        name="header"
        value=${ifDefined(this.wordcast?.header)}
        type="text"
        required
      ></sl-input>

      <sl-input
        id="pos"
        name="pos"
        value=${ifDefined(this.wordcast?.pos)}
        type="text"
        placeholder="enter pos"
        required
      ></sl-input>

      <sl-input
        id="sense"
        name="sense"
        value=${ifDefined(this.wordcast?.sense)}
        type="text"
        placeholder="enter sense of the wordlet"
        clearable
      ></sl-input>

      ${this.wordcast && Array.isArray(this.wordcast.images)
        ? html`
            <div class="images">
              ${this.wordcast.images.map(
                (x) =>
                  html`<img id=${this.wordcast.images?.indexOf(x)} src=${x} /> `
              )}
            </div>
          `
        : html``}
    `;
  }

  get htmlCreateNewWordcast() {
    return html`
      <sl-input
        name="header"
        type="text"
        placeholder="enter header words"
        required
        clearable
      ></sl-input>

      <sl-select id="pos" name="pos" placeholder="Select POS">
        <sl-menu-item value="noun">noun</sl-menu-item>
        <sl-menu-item value="verb">verb</sl-menu-item>
        <sl-menu-item value="adjective">adjective</sl-menu-item>
        <sl-menu-item value="adverb">adverb</sl-menu-item>
      </sl-select>

      <sl-input
        id="sense"
        name="sense"
        type="text"
        placeholder="enter sense of wordlet"
        clearable
        required
      ></sl-input>

      <sl-input
        id="imageurl1"
        name="images"
        type="text"
        placeholder="enter image url"
        clearable
      ></sl-input>

      <sl-input
        id="imageurl2"
        name="images"
        type="text"
        placeholder="enter image url"
        clearable
      ></sl-input>

      <sl-input
        id="imageurl3"
        name="images"
        type="text"
        placeholder="enter image url"
        clearable
      ></sl-input>

      <sl-input
        id="imageurl4"
        name="images"
        type="text"
        placeholder="enter image url"
        clearable
      ></sl-input>

      <sl-input
        id="imageurl5"
        name="images"
        type="text"
        placeholder="enter image url"
        clearable
      ></sl-input>
    `;
  }

  render() {
    return html`
      <div class="form_wrapper">
        <form class="form_body" @submit=${this.onSubmit}>
          ${this.isEdit ? this.htmlEditWordcast : this.htmlCreateNewWordcast}
          <sl-divider style="--spacing: 2rem;"></sl-divider>
          <sl-button type="submit" variant="default">Submit</sl-button>
        </form>
      </div>
    `;
  }

  static get styles() {
    return [
      cssFormStyle,
      cssScrollbar,
      css`
        .images {
          overflow-x: auto;
          overflow-y: auto;
          max-height: 40%;
        }
      `,
    ];
  }
}
