import { css, html, PropertyValues } from "lit";
import { customElement, query, queryAll, state } from "lit/decorators.js";
import { IMediaInfo, ITranscript } from "../state/models/resource";
import {
  Connected,
  State,
  ResourceSelectors,
  dispatch,
  MediaPlayerSelectors,
} from "./connected";
import { copyrighter } from "../config";
import {
  cssAppLayout,
  cssMainGrid,
  cssScrollbar,
  SharedStyles,
} from "../components/sharedStyles";
import { cssMediaBox, cssMultimediaPlayer } from "./media-editor-style";

import "../components/word-cast";

@customElement("media-editor")
export class MediaEditor extends Connected {
  @state() activeMedia: IMediaInfo;
  @state() mediaId: string | undefined;
  @state() mediaUrl: string | undefined;
  @state() isVideo: boolean | undefined;

  @state() playerActivated: boolean;
  // @state() isPlaying: boolean;
  @state() isMutedByHost: boolean;
  @state() isTranscriptOn: boolean;
  @state() playHeadPosition: number;
  @state() is_host_muted: boolean;
  @state() is_remote_muted_by_host: boolean;
  @state() isHostPlaying: boolean = false;
  @state() currentPage: ITranscript[] | undefined;

  @state() transcript: ITranscript[] | undefined = undefined;
  private pageSize: number = 500;
  // private pageNumber: number = 1;
  private pageStartIndex: number = 0; // this.pageSize * (this.pageNumber - 1);

  @query(".main") mainboard: HTMLDivElement;
  @queryAll(".ticks") ticks: HTMLSpanElement;
  @query("#streamplayer") streamplayer: HTMLVideoElement;
  @query("#videoElement") player: HTMLVideoElement;
  @query("#wordcastview") wordcastview: HTMLDivElement;
  @query("#transcriptview") transcriptview: HTMLDivElement;

  mapState(state: State) {
    return {
      activeMedia: ResourceSelectors.activeMedia(state),
      mediaUrl: ResourceSelectors.mediaUrl(state),
      mediaId: ResourceSelectors.mediaId(state),
      isVideo: ResourceSelectors.isVideo(state),
      transcript: ResourceSelectors.transcript(state),

      playerActivated: MediaPlayerSelectors.playerActivated(state),
      isTranscriptOn: MediaPlayerSelectors.isTranscriptOn(state),
      isMutedByHost: MediaPlayerSelectors.isMutedByHost(state),
      playHeadPosition: MediaPlayerSelectors.playHeadPosition(state),
    };
  }

  async updated(changedProps: PropertyValues) {
    if (!this.playerActivated) return;

    if (changedProps.has("playHeadPosition")) {
      this.activateCurrentPage();
      this.markCurrentPosition();
    }

    if (changedProps.has("mediaUrl") && this.mediaUrl) {
      if (!this.player || !this.mediaUrl) return;

      this.pageStartIndex = 0;
      if (this.currentPage) this.currentPage = [];
      this.transcriptview.style.display = "block";

      // this.player.src = this.mediaUrl;
      await this.fetchVideo();
    }

    if (changedProps.has("isHostPlaying")) {
      if (!this.player || !this.mediaUrl) return;

      if (this.isHostPlaying) {
        await this.player.play();
        this.player.style.opacity = "100%";
      } else {
        this.player.pause();
        this.player.style.opacity = "15%";
      }
    }

    if (changedProps.has("isTranscriptOn")) {
      this.transcriptview.style.display = this.isTranscriptOn
        ? "block"
        : "none";
    }

    if (changedProps.has("isMutedByHost")) {
      if (!this.player) return;
      this.isMutedByHost
        ? (this.player.muted = true)
        : (this.player.muted = false);
    }
  }

  onFirstPage(e) {}

  onLastPage(e) {}

  onPrevPage(e) {
    if (!this.transcript) return;
    let prevPageStartIndex = Math.max(0, this.pageStartIndex - this.pageSize);
    let currentPosition = this.transcript[prevPageStartIndex].start_time;
    dispatch.mediaplayer.update({
      playHeadPosition: Math.floor(parseFloat(currentPosition.toString())),
    });
  }

  onNextPage(e) {
    if (!this.transcript) return;
    let lastIndex = this.transcript.length - 1;
    let nextPageStartIndex = Math.min(
      lastIndex,
      this.pageStartIndex + this.pageSize
    );
    let currentPosition = this.transcript[nextPageStartIndex].start_time;
    dispatch.mediaplayer.update({
      playHeadPosition: Math.ceil(parseFloat(currentPosition.toString())),
    });
  }

  onHostPlayPause(e) {
    if (!this.player) return;
    this.isHostPlaying = !this.isHostPlaying;
    // dispatch.mediaplayer.update({
    //   isPlaying: this.isHostPlaying,
    // });
  }

  onHostMuteUnmute(e: any) {
    this.is_host_muted = !this.is_host_muted;
    this.is_host_muted
      ? (this.player.muted = true)
      : (this.player.muted = false);
  }

  onRemoteMuteUnmute(e) {
    this.is_remote_muted_by_host = !this.is_remote_muted_by_host;
    dispatch.mediaplayer.update({
      isMutedByHost: this.is_remote_muted_by_host,
    });
  }

  onTranscriptSwitch(e) {
    if (!this.currentPage) return;
    this.isTranscriptOn = !this.isTranscriptOn;
    dispatch.mediaplayer.update({
      isTranscriptOn: this.isTranscriptOn,
    });
  }

  onHostWordSelected(e: any) {
    if (!this.currentPage) return;
    let currentPosition = this.currentPage[e.target.id]["start_time"];
    let eventData = {
      playHeadPosition: Math.round(parseFloat(currentPosition.toString())), // fixes "float number causing bad json request"
    };
    dispatch.mediaplayer.update(eventData);
  }

  onTimeUpdate(e) {
    dispatch.mediaplayer.update({ playHeadPosition: e.target.currentTime });
  }

  onEnded(e) {
    this.isHostPlaying = false;
    this.pageStartIndex = 0;
  }

  async fetchVideo() {
    if (!this.mediaUrl || !this.player) return;

    const res = await fetch(this.mediaUrl, {
      headers: {
        Range: "bytes=0-99",
      },
    });

    const blob = await res.blob();
    if (blob) {
      this.player.src = URL.createObjectURL(blob);

      // Load the new resource
      this.player.load();

      // console.info("Ready!", this.player.src);
    } else {
      console.warn("Can not load media");
    }
  }

  activateCurrentPage() {
    if (!this.transcript) return;

    let indx = this.getIndexFromCurrentTime(this.playHeadPosition);
    if (!indx || indx < 0) return;

    if (indx >= this.pageStartIndex + this.pageSize) {
      this.pageStartIndex += this.pageSize;
    } else if (indx <= this.pageStartIndex - this.pageSize) {
      this.pageStartIndex -= this.pageSize;
    }

    this.currentPage = this.transcript.slice(
      this.pageStartIndex,
      this.pageStartIndex + this.pageSize
    );
  }

  markCurrentPosition() {
    if (!this.currentPage) return;
    this.currentPage.forEach((tick) => {
      let idx = this.currentPage!.indexOf(tick);
      if (this.ticks[idx]) {
        if (this.playHeadPosition >= parseFloat(tick.start_time.toString())) {
          this.ticks[idx].style.color = "rgba(202, 209, 174, 1)";
          this.ticks[idx].scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        } else {
          this.ticks[idx].style.color = "rgba(202, 209, 174, 0.55)";
        }
      }
    });
  }

  getIndexFromCurrentTime(currentPosition: number) {
    if (!this.activeMedia || !Array.isArray(this.transcript!)) return;

    let indx = this.transcript.findIndex(
      (m) =>
        currentPosition >= parseFloat(m.start_time.toString()) &&
        currentPosition < parseFloat(m.end_time.toString())
    );

    return indx;
  }

  progress_rate() {
    if (!this.player || !this.activeMedia) return;

    let duration = this.activeMedia.duration!; //this.player.duration;
    let str: string = "0";
    let rate: number;

    if (!isNaN(duration) && duration > 0) {
      rate = (100 * this.playHeadPosition) / duration;
      str = `${rate.toFixed(2)}%`;
      return { str, rate };
    } else {
      return {};
    }
  }

  formatTime(seconds: number) {
    let min = Math.floor(seconds / 60);
    let sec = Math.floor(seconds - min * 60);
    let strSec = sec < 10 ? `0${sec}` : sec.toString();
    return `${min}:${strSec}`;
  }

  get htmlMediaControls() {
    return html`<div class="board_controls">
      <sl-tooltip content="search something here" placement="top" hoist>
        <sl-input
          type="search"
          placeholder="search"
          size="small"
          pill
          autofocus
          clearable
          ><sl-icon name="search" slot="prefix" class="button_icon"></sl-icon
        ></sl-input>
      </sl-tooltip>

      <sl-divider vertical></sl-divider>

      <sl-tooltip content="back" placement="top" hoist>
        <sl-button
          @click=${this.onPrevPage}
          size="small"
          variant="text"
          style="width:1.8rem;"
        >
          <sl-icon
            class="control-item"
            library="tx-icons"
            name="backward"
          ></sl-icon
        ></sl-button>
      </sl-tooltip>

      <sl-tooltip content="back" placement="top" hoist>
        <sl-button
          @click=${this.onNextPage}
          size="small"
          variant="text"
          style="width:1.8rem;"
        >
          <sl-icon
            class="control-item"
            library="tx-icons"
            name="forward"
          ></sl-icon
        ></sl-button>
      </sl-tooltip>

      <sl-tooltip
        content=${this.isHostPlaying ? "pause" : "play"}
        placement="top"
        hoist
      >
        <sl-button
          @click=${this.onHostPlayPause}
          id="play-pause"
          size="small"
          variant="text"
          style="width:1.8rem;"
        >
          <sl-icon
            class="control-item"
            library="tx-icons"
            name=${this.isHostPlaying ? "pause" : "play"}
          ></sl-icon
        ></sl-button>
      </sl-tooltip>

      <sl-tooltip
        content=${this.is_host_muted ? "unmute" : "mute"}
        placement="top"
        hoist
      >
        <sl-button
          @click=${this.onHostMuteUnmute}
          size="small"
          variant="text"
          style="width:1.8rem;"
        >
          <sl-icon
            class="control-item"
            library="tx-icons"
            name=${this.is_host_muted ? "mute" : "speaker"}
          ></sl-icon
        ></sl-button>
      </sl-tooltip>

      <sl-tooltip
        content=${this.isTranscriptOn ? "hide text" : "show-text"}
        placement="top"
        hoist
      >
        <sl-button
          @click=${this.onTranscriptSwitch}
          size="small"
          variant="text"
          style="width:1.8rem;"
        >
          <sl-icon
            class="control-item"
            library="tx-icons"
            name=${this.isTranscriptOn ? "transcript_off" : "transcript_on"}
          ></sl-icon
        ></sl-button>
      </sl-tooltip>

      <sl-tooltip content="progress" placement="top" hoist>
        <sl-button
          size="small"
          variant="text"
          style="width:3rem; color:rgb(var(--sl-color-amber-500) / 75%);"
          readonly
          pill
          >${this.progress_rate()?.str}</sl-button
        >
      </sl-tooltip>
    </div> `;
  }

  get htmlTextPassage() {
    if (!this.currentPage) return;
    return html`
      ${this.currentPage.map(
        (t) =>
          html`
            <span
              class="ticks"
              id=${this.currentPage!.indexOf(t)}
              @click=${this.onHostWordSelected}
              >${t.text}</span
            >
          `
      )}
    `;
  }

  get htmlWordcastView() {
    if (!this.activeMedia || !this.activeMedia.wordcast) return;
    return html`${this.activeMedia.wordcast.map(
      (x) => html` <word-cast
        id=${x.id}
        lemma=${x.header}
        .senses=${[x.sense]}
        .images=${x.images}
      ></word-cast>`
    )}`;
  }

  get htmlMediaEditor() {
    return html` <div class="wordcast_grid">
      <div id="transcriptview" class="reading_text">
        ${this.htmlTextPassage}
      </div>
      <div class="mediaview">
        <video
          id="videoElement"
          playsinline
          style=${this.isVideo ? "width:100%;" : "width:0.001%;"}
          @timeupdate=${this.onTimeUpdate}
          @ended=${this.onEnded}
        ></video>
        <div class="wordcast">${this.htmlWordcastView}</div>
      </div>
    </div>`;
  }

  render() {
    return html` <sl-resize-observer
      ><div class="main">
        <div class="searchbar">${this.htmlMediaControls}</div>
        <div id="mainboard_container" class="mainboard">
          ${this.htmlMediaEditor}
        </div>
        <div class="footsy footer__copyright">
          <p>&copy; ${copyrighter}</p>
        </div>
      </div></sl-resize-observer
    >`;
  }

  static get styles() {
    return [
      SharedStyles,
      cssScrollbar,
      cssAppLayout,
      cssMainGrid,
      cssMediaBox,
      cssMultimediaPlayer,
      css``,
    ];
  }
}
