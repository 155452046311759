import { createModel } from "@captaincodeman/rdx";
import { State, Store } from "../store";
import { createSelector } from "reselect";
import { passwordLogin } from "../../lib/matrix/MatrixAuth";
import {
  IMatrixRoomlet,
  MatrixClient,
  IAccountDataEvent,
  IRoomMessage,
  IMatrixTimelineEvent,
} from "../../lib/matrix/MatrixClient";
import {
  M_AVATAR_HEIGHT,
  M_AVATAR_WIDTH,
} from "../../lib/matrix/models/constants";
// import { MatrixEvent } from "../../lib/matrix/models/events/Event";
// import { PresenceEventContent } from "../../lib/matrix/models/events/PresenceEvent";
import {
  FileInfo,
  // MathMessageEventContent,
  MessageEventContent,
  roomMessageType,
  ThumbnailInfo,
  TimedFileInfo,
  VideoFileInfo,
} from "../../lib/matrix/models/events/MessageEvent";
import { getHttpUriForMxc } from "../../lib/matrix/helpers/media-repo";
import { Attachment } from "@github/file-attachment-element";
import {
  getBlobSafeMimeType,
  getImageDimension,
  getMsgtypeFromMimetype,
  getVideoThumbnail,
  loadVideo,
} from "../../lib/matrix/helpers/utils";
import { getHashNumber } from "../../lib/utils/maths";

import log from "loglevel";
import { randomizeArray } from "../../lib/utils/arrays";

log.enableAll();

export interface IMatrixRoomMember {
  matrixId: string;
  // presenceStatus: PresenceEventContent;
  deviceId?: string;
  displayName?: string;
  avatarUrl?: string;
}

export interface IMatrixState {
  connected: boolean;
  session: MatrixClient | undefined;
  matrixId: string;
  deviceId: string;
  displayName: string;
  avatarUrl: string;
  homeserverUrl: string;
  joinedRooms: any[];
  currentRoom: IMatrixRoomlet | null;
  currentMembers: IMatrixRoomMember[];
  roomMessages: IRoomMessage[];
  error: string;
}

const initialState: IMatrixState = {
  connected: false,
  session: undefined,
  matrixId: "",
  deviceId: "",
  displayName: "",
  avatarUrl: "",
  homeserverUrl: "",
  joinedRooms: [],
  currentRoom: null,
  currentMembers: [],
  roomMessages: [],
  error: "",
};

export default createModel({
  state: initialState,

  reducers: {
    init(state, payload: any) {
      return {
        ...state,
        session: payload.session,
        matrixId: payload.matrixId,
        deviceId: payload.deviceId,
        displayName: payload.displayName,
        avatarUrl: payload.avatarUrl,
        homeserverUrl: payload.homeserverUrl,
        joinedRooms: payload.joinedRooms,
        connected: true,
      };
    },

    setCurrentRoom(state, currentRoom: IMatrixRoomlet) {
      return {
        ...state,
        currentRoom,
      };
    },

    setCurrentRoomMembers(state, currentMembers: IMatrixRoomMember[]) {
      return {
        ...state,
        currentMembers,
      };
    },

    updateTimeline(state, payload: IRoomMessage[]) {
      return { ...state, roomMessages: payload };
    },

    errored(state, err) {
      return {
        ...state,
        active: false,
        error: err,
      };
    },

    reset() {
      return initialState;
    },
  },

  effects(store: Store) {
    const dispatch = store.getDispatch();

    return {
      async createSession(payload: { username: string; password: string }) {
        const username = payload.username.includes("@bendemy.com") // hack to be removed later
          ? payload.username.split("@")[0]
          : payload.username;

        const { userId, deviceId, accessToken, homeserverUrl } =
          await passwordLogin(username, payload.password);

        // console.log("homeserverUrl", homeserverUrl);

        const mx = new MatrixClient(homeserverUrl, accessToken);

        dispatch.matrix.initialize({
          mx,
          userId,
          deviceId,
          homeserverUrl,
        });
      },

      async initialize(payload: {
        mx: MatrixClient;
        userId: string;
        deviceId: string;
        homeserverUrl: string;
      }) {
        const { mx, userId, deviceId, homeserverUrl } = payload;
        const { displayName, avatarUrl } = await mx.getUserProfile(userId);

        // console.log("getUserProfile", displayName, avatarUrl);

        const rooms = await mx.joinedRooms();
        const mxrooms = rooms.joined_rooms;

        const joinedRooms: IMatrixRoomlet[] = await Promise.all(
          mxrooms.map(async (roomId: string) => {
            if (!roomId) throw new Error("roomId is empty.");
            const roomNumber = getHashNumber(roomId);
            // console.log("initialize xxxroomNumber", roomNumber, roomId);

            const roomName = (await mx.getRoomStateName(roomId)).name;
            const roomAvatar = getHttpUriForMxc(
              mx.homeserverUrl,
              (await mx.getRoomStateAvatar(roomId)).url,
              M_AVATAR_WIDTH,
              M_AVATAR_HEIGHT
            );

            const roomTopic = (await mx.getRoomStateTopic(roomId))!.topic;

            return {
              roomId,
              roomName,
              roomAvatar,
              roomTopic,
              roomNumber,
            };
          })
        );

        dispatch.matrix.init({
          session: mx,
          matrixId: userId,
          deviceId,
          homeserverUrl,
          displayName,
          avatarUrl,
          joinedRooms,
        });

        if (!joinedRooms || joinedRooms.length < 1) return;

        const recentRoom: IMatrixRoomlet = randomizeArray(joinedRooms)[0];

        dispatch.matrix.loadCurrentRoom({
          roomId: recentRoom.roomId,
        });
        dispatch.matrix.loadRoomMembers(recentRoom.roomId);

        let filter = {
          room: {
            state: { lazy_load_members: true, types: ["*"] },
            timeline: {
              limit: 100,
              types: ["*"],
            },
          },
        };

        await mx.start(true, filter);

        dispatch.matrix.mxEventHandlers(mx);
      },

      mxEventHandlers(mxSession: MatrixClient) {
        if (!mxSession) return;
        // mxSession.on(
        //   "tx_presence",
        //   async (
        //     mx: MatrixClient,
        //     event: MatrixEvent<PresenceEventContent>
        //   ) => {
        //     dispatch.matrix.handlePresenceEvent({ mx, event });
        //   }
        // );

        mxSession.on(
          "tx_user_account_data",
          async (mx: MatrixClient, event: IAccountDataEvent) => {}
        );

        mxSession.on(
          "tx_room_message",
          async (
            mx: MatrixClient,
            roomId: string,
            event: IMatrixTimelineEvent
          ) => {
            dispatch.matrix.loadRoomMessages({ roomId, mx, event });
          }
        );
      },

      // handlePresenceEvent(payload: {
      //   mx: MatrixClient;
      //   event: MatrixEvent<PresenceEventContent>;
      // }) {
      //   const { mx, event } = payload;
      //   let currentMembers = store.getState().matrix.currentMembers;

      //   currentMembers.forEach((m) => {
      //     if (m.matrixId === event.sender) {
      //       m.presenceStatus.presence = event.content.presence;
      //       dispatch.matrix.setCurrentRoomMembers(currentMembers);
      //     }
      //   });
      // },

      async loadRoomMessages(payload: {
        roomId: string;
        mx: MatrixClient;
        event: IMatrixTimelineEvent;
      }) {
        const { roomId, mx, event } = payload;
        if (event.type !== "m.room.message") return;

        const res = await mx.getRoomStateName(roomId);
        const roomName = res.name;
        const { displayName, avatarUrl } = await mx.getUserProfile(
          event.sender
        );

        const isFile = !(
          event.content.msgtype ===
          (roomMessageType.m_text ||
            roomMessageType.m_notice ||
            roomMessageType.m_emote)
        );

        const mediaUrl = isFile
          ? getHttpUriForMxc(mx.homeserverUrl, event.content.url)
          : undefined;

        let tl: IRoomMessage[] = store.getState().matrix.roomMessages;
        // !!!! use of spread operator is necessary for change to the array to be detected by ui component
        tl = [
          ...tl,
          {
            roomId,
            roomName,
            sender: event.sender,
            displayName,
            avatarUrl,
            time: event.origin_server_ts,
            message: {
              text: event.content.body,
              type: event.content.msgtype,
              url: mediaUrl,
              info: event.content.info,
            },
          },
        ];

        tl.sort((x: IRoomMessage, y: IRoomMessage) => x.time - y.time);

        dispatch.matrix.updateTimeline(tl);
      },

      async loadCurrentRoom(payload: { roomId: string }) {
        const joinedRooms = store.getState().matrix.joinedRooms;
        const { roomId } = payload;

        let currentRoom: IMatrixRoomlet;

        if (roomId) {
          currentRoom = joinedRooms.find((x) => x.roomId === roomId);
        } else {
          throw new Error("missing roomID ");
        }

        dispatch.matrix.setCurrentRoom(currentRoom);
        dispatch.matrix.loadRoomMembers(currentRoom.roomId);
      },

      async loadRoomMembers(roomId: string) {
        const mx = store.getState().matrix.session!;
        const members = await mx.getMembersByRoomId(roomId);
        dispatch.matrix.setCurrentRoomMembers(members);

        // const currentMembers: IMatrixRoomMember[] = await Promise.all(
        //   members.map(async (m) => {
        //     const presenceStatus: PresenceEventContent =
        //       await mx.getUserPresence(m.matrixId);
        //     return { ...m, presenceStatus };
        //   })
        // );

        // dispatch.matrix.setCurrentRoomMembers(currentMembers);
      },

      async sendVoiceMail(payload: { roomId: string; audioblob: Blob }) {
        const { roomId, audioblob } = payload;
        if (!audioblob) return;

        const sent: string[] = [];
        const mx = store.getState().matrix.session!;
        const userid = await mx.getUserId();

        const filename = `voice_message_${userid}_${Date.now()}.mp3`;

        const fx = new File([payload.audioblob], filename, {
          lastModified: Date.now(),
          type: getBlobSafeMimeType(payload.audioblob.type),
        });

        const fd = new FormData();
        const fileobj = fx;

        fd.append("voicemail", fileobj, filename);

        const info = {
          size: fileobj.size,
          mimetype: getBlobSafeMimeType(fileobj.type),
        };
        const msgtype = getMsgtypeFromMimetype(info.mimetype);
        const resp: any = await mx.uploadContent(fd, filename);
        const url = resp.content_uri;
        const content: MessageEventContent = {
          msgtype,
          body: filename,
          filename,
          url,
          info,
        };

        const tr = await mx.sendMessage(roomId, JSON.stringify(content));
        sent.push(tr);
      },

      async sendAttachments(payload: {
        roomId: string;
        attachments: Attachment[] | null;
      }) {
        const { roomId, attachments } = payload;
        if (!attachments || attachments.length < 1) return;

        const mx = store.getState().matrix.session!;

        const sent: string[] = [];

        attachments.forEach(async (a: Attachment) => {
          const fileobj = a.file;
          const filename = a.file.name;
          const fileType = getBlobSafeMimeType(fileobj.type);
          let info: ThumbnailInfo | FileInfo | VideoFileInfo | TimedFileInfo = {
            size: fileobj.size,
            mimetype: fileType,
          };

          const msgtype = getMsgtypeFromMimetype(info.mimetype!);

          if (msgtype === roomMessageType.m_image) {
            const imgdim: { w: number; h: number } = await getImageDimension(
              fileobj
            );
            info = {
              size: fileobj.size,
              mimetype: fileType,
              w: imgdim.w,
              h: imgdim.h,
            };
          } else if (msgtype === roomMessageType.m_video) {
            const video: any = await loadVideo(fileobj);

            const thumbnailData: any = await getVideoThumbnail(
              video,
              video.videoWidth,
              video.videoHeight,
              "image/jpeg"
            );

            const thumbnailUploadData: any = await mx.uploadContent(
              thumbnailData.thumbnail
            );

            info = {
              size: fileobj.size,
              mimetype: fileType,
              w: video.videoWidth,
              h: video.videoHeight,
              thumbnail_info: thumbnailData.info,
              thumbnail_url: thumbnailUploadData.url,
            };
          } else if (msgtype === roomMessageType.m_audio) {
            info = {
              size: fileobj.size,
              mimetype: fileType,
            };
          } else {
            info = {
              size: fileobj.size,
              mimetype: fileType,
            };
          }

          const resp: any = await mx.uploadContent(fileobj, filename);

          const url = resp.content_uri;

          const content: MessageEventContent = {
            msgtype,
            body: filename,
            filename,
            url,
            info,
          };

          const tr = await mx.sendMessage(roomId, JSON.stringify(content));

          sent.push(tr);
        });
      },

      sendTextMessage(payload: { roomId: string; message: string }) {
        const mx = store.getState().matrix.session;
        if (!mx) return;
        mx.sendText(payload.roomId, payload.message);
      },
    };
  },
});

const getState = (state: State) => state.matrix;

export namespace MatrixSelectors {
  export const connected = createSelector(
    [getState],
    (state) => state.connected
  );

  export const session = createSelector([getState], (state) => state.session);

  export const matrixId = createSelector([getState], (state) => state.matrixId);
  export const deviceId = createSelector([getState], (state) => state.deviceId);

  export const displayName = createSelector(
    [getState],
    (state) => state.displayName
  );

  export const avatarUrl = createSelector(
    [getState],
    (state) => state.avatarUrl
  );

  export const joinedRooms = createSelector(
    [getState],
    (state) => state.joinedRooms
  );

  export const currentRoom = createSelector(
    [getState],
    (state) => state.currentRoom
  );

  export const currentMembers = createSelector(
    [getState],
    (state) => state.currentMembers
  );

  export const roomMessages = createSelector(
    [getState],
    (state) => state.roomMessages
  );
}
