import log from "loglevel";
import { createNanoEvents, Emitter } from "nanoevents";
// import { htmlToText } from "html-to-text";

import {
  M_AVATAR_WIDTH,
  M_AVATAR_HEIGHT,
  TX_LAST_SYNC,
  MX_API_HEAD,
} from "./models/constants";

import { getHttpUriForMxc } from "./helpers/media-repo";

import { doHttpRequest, httpBodyType, httpMethod } from "./http";
// import { PresenceEvent, PresenceState } from "./models/events/PresenceEvent";
import { MatrixProfileInfo } from "./models/MatrixProfile";
import { MatrixEvent } from "./models/events/Event";
import {
  PresenceEventContent,
  PresenceState,
} from "./models/events/PresenceEvent";
import { EventKind } from "./models/events/EventKind";
import { IPreprocessor } from "./helpers/IPreprocessor";
import {
  EventContentType,
  FileInfoType,
  MathLiveFormat,
  roomMessageType,
} from "./models/events/MessageEvent";
import { RoomAvatarEventContent } from "./models/events/RoomAvatarEvent";
import { RoomNameEventContent } from "./models/events/RoomNameEvent";
import { RoomTopicEventContent } from "./models/events/RoomTopicEvent";
import { htmlToText } from "html-to-text";
import { IStorageProvider } from "./storage/IStorageProvider";
import { IFilterInfo } from "./helpers/IFilter";
import { ServiceUrls } from "../../config";

export interface IMatrixJoinedRoomsResponse {
  joined_rooms: string[];
}

export interface IMatrixSyncResponse {
  account_data: { events: any };
  presence: { events: any };
  rooms: { join: any; leave: any; invite: any };
  next_batch: string;
}

export interface IAccountDataEvent {
  type: string;
  content: any;
}

export interface IWhoAmI {
  user_id: string;
  device_id?: string;
}

export interface IMatrixRoomlet {
  roomId: string;
  roomName: string;
  roomAvatar?: string;
  roomTopic?: string;
  roomNumber: number;
}

export interface IMatrixTimelineEvent {
  content: EventContentType;
  type: string;
  room_id: string;
  event_id: string;
  sender: string;
  origin_server_ts: number;
  unsigned: { age: string };
}

export interface IRoomMessage {
  roomId: string;
  roomName: string;
  sender: string;
  displayName?: string;
  avatarUrl?: string;
  time: number;
  message: IMessage;
}

export interface IMessage {
  text: string;
  type: roomMessageType;
  url?: string;
  info?: FileInfoType;
  math_format?: MathLiveFormat;
  math_body?: string;
}

export interface ITxMatrixEvent {
  tx_user_account_data: (
    client: MatrixClient,
    event: IAccountDataEvent
  ) => Promise<void>;

  tx_presence: (
    client: MatrixClient,
    event: MatrixEvent<PresenceEventContent>
  ) => Promise<void>;

  tx_room_account_data: (
    client: MatrixClient,
    roomId: string,
    event: IAccountDataEvent
  ) => Promise<void>;

  tx_room_leave: (
    client: MatrixClient,
    roomId: string,
    event: any
  ) => Promise<void>;

  tx_room_invite: (
    client: MatrixClient,
    roomId: string,
    event: any
  ) => Promise<void>;

  tx_room_join: (
    client: MatrixClient,
    roomId: string,
    event: any
  ) => Promise<void>;

  tx_room_message: (
    client: MatrixClient,
    roomId: string,
    event: any
  ) => Promise<void>;

  tx_room_archived: (
    client: MatrixClient,
    roomId: string,
    event: any
  ) => Promise<void>;

  tx_room_upgraded: (
    client: MatrixClient,
    roomId: string,
    event: any
  ) => Promise<void>;

  tx_room_event: (
    client: MatrixClient,
    roomId: string,
    event: IMatrixTimelineEvent
  ) => Promise<void>;
}

log.enableAll();

// const SYNC_BACKOFF_MIN_MS = 5000;
// const SYNC_BACKOFF_MAX_MS = 15000;

/**
 * A client that is capable of interacting with a matrix homeserver.
 */
export class MatrixClient {
  /**
   * The presence status to use while syncing. The valid values are "online" to set the account as online,
   * "offline" to set the user as offline, "unavailable" for marking the user away, and null for not setting
   * an explicit presence (the default).
   *
   * Has no effect if the client is not syncing. Does not apply until the next sync request.
   */
  public syncingPresence: PresenceState; // "online" | "offline" | "unavailable" | null = null;
  /**
   * The number of milliseconds to wait for new events for on the next sync.
   *
   * Has no effect if the client is not syncing. Does not apply until the next sync request.
   */
  public syncingTimeout = 30000;

  /**
   * Set this to true to have the client only persist the sync token after the sync
   * has been processed successfully. Note that if this is true then when the sync
   * loop throws an error the client will not persist a token.
   */
  // protected persistTokenAfterSync = false;

  private userId: string = "";
  private requestId: number = 0;
  private stopSyncing: boolean = false;
  private filterId: number = 0;
  private lastJoinedRoomIds: string[] = [];
  private eventProcessors: { [eventType: string]: IPreprocessor[] } = {};

  private emitter: Emitter;

  // private localStore = {
  //   read: () => {
  //     try {
  //       return localStorage.getItem(TX_LAST_SYNC);
  //     } catch (err) {
  //       return undefined;
  //     }
  //   },
  //   write: (since: string) => {
  //     localStorage.setItem(TX_LAST_SYNC, since);
  //   },
  // };

  private localStore: IStorageProvider = {
    getSyncToken: () => {
      return localStorage.getItem(TX_LAST_SYNC);
    },

    setSyncToken: (since: string) => {
      localStorage.setItem(TX_LAST_SYNC, since);
    },

    getFilter: () => {
      return JSON.parse(localStorage.getItem("tx_filter")!);
    },

    setFilter: (filter: IFilterInfo) => {
      localStorage.setItem("tx_filter", JSON.stringify(filter));
    },

    readValue: (key: string) => {
      return JSON.parse(localStorage.getItem(key)!);
    },

    storeValue: (key: string, value: string) => {
      localStorage.setItem(key, value);
    },
  };

  /**
   * Creates a new matrix client
   * @param {string} homeserverUrl The homeserver's client-server API URL
   * @param {string} accessToken The access token for the homeserver
   * @param {IStorageProvider} storage The storage provider to use. Defaults to MemoryStorageProvider.
   * @param {ICryptoStorageProvider} cryptoStore Optional crypto storage provider to use. If not supplied,
   * end-to-end encryption will not be functional in this client.
   */
  constructor(
    public readonly homeserverUrl: string,
    public readonly accessToken: string
  ) {
    log.info("creating matrix client");
    this.emitter = createNanoEvents<ITxMatrixEvent>();
  }

  on<E extends keyof ITxMatrixEvent>(event: E, callback: ITxMatrixEvent[E]) {
    return this.emitter.on(event, callback);
  }

  once(event: any, callback: any) {
    const unbind = this.emitter.on(event, (...args) => {
      unbind();
      callback(...args);
    });
    return unbind;
  }

  private async makeRequest<T>(
    endpoint: string,
    options?: {
      query?: string[];
      method?: httpMethod;
      body?: httpBodyType;
      isRawResponse?: boolean;
    }
  ) {
    const opts = !options
      ? {
          accessToken: this.accessToken,
        }
      : {
          query: options.query,
          method: options.method,
          body: options.body,
          isRawResponse: options.isRawResponse,
          accessToken: this.accessToken,
        };

    return <T>await doHttpRequest(this.homeserverUrl!, endpoint, opts);
  }

  protected async startSyncLoop(since: string | null | undefined) {
    if (this.stopSyncing) {
      log.info("MatrixClient", "Client stop requested - stopping sync");
      return;
    }

    const syncResponse = await this.doSync(since);

    await this.processSyncEvents(syncResponse);

    this.localStore.setSyncToken(syncResponse.next_batch);

    this.startSyncLoop(syncResponse.next_batch);
  }

  protected async processSyncEvents(raw: IMatrixSyncResponse) {
    if (raw.account_data && raw.account_data.events) {
      for (const event of raw.account_data.events) {
        this.emitter.emit("tx_user_account_data", this, event);
      }
    }

    if (raw.presence && raw.presence.events) {
      for (const event of raw.presence.events) {
        this.emitter.emit("tx_presence", this, event);
      }
    }

    if (!raw.rooms) return; // nothing more to process

    const leftRooms = raw.rooms.leave || {};
    const inviteRooms = raw.rooms.invite || {};
    const joinedRooms = raw.rooms.join || {};

    // Process rooms we've left first
    for (const roomId in leftRooms) {
      const room = leftRooms[roomId];

      if (room.account_data && room.account_data.events) {
        for (const event of room.account_data.events) {
          this.emitter.emit("tx_room_account_data", this, roomId, event);
        }
      }

      if (!room.timeline || !room.timeline.events) continue;

      let leaveEvent: any;

      for (const event of room.timeline.events) {
        if (event.type !== "m.room.member") continue;
        if (event.state_key !== (await this.getUserId())) continue;

        const oldAge =
          leaveEvent && leaveEvent.unsigned && leaveEvent.unsigned.age
            ? leaveEvent.unsigned.age
            : 0;
        const newAge =
          event.unsigned && event.unsigned.age ? event.unsigned.age : 0;

        if (leaveEvent && oldAge < newAge) continue;

        leaveEvent = event;
      }

      if (!leaveEvent) {
        log.warn(
          "MatrixClient",
          "Left room " + roomId + " without receiving an event"
        );
        continue;
      }

      leaveEvent = await this.processEvent(leaveEvent);
      this.emitter.emit("tx_room_leave", this, roomId, leaveEvent);

      this.lastJoinedRoomIds = this.lastJoinedRoomIds.filter(
        (r) => r !== roomId
      );
    }

    // Process rooms we've been invited to
    for (const roomId in inviteRooms) {
      const room = inviteRooms[roomId];
      if (!room.invite_state || !room.invite_state.events) continue;

      let inviteEvent: any;
      for (const event of room.invite_state.events) {
        if (event.type !== "m.room.member") continue;
        if (event.state_key !== (await this.getUserId())) continue;
        if (!event.content) continue;
        if (event.content.membership !== "invite") continue;

        const oldAge =
          inviteEvent && inviteEvent.unsigned && inviteEvent.unsigned.age
            ? inviteEvent.unsigned.age
            : 0;

        const newAge =
          event.unsigned && event.unsigned.age ? event.unsigned.age : 0;

        if (inviteEvent && oldAge < newAge) continue;

        inviteEvent = event;
      }

      if (!inviteEvent) {
        log.warn(
          "MatrixClient",
          "Invited to room " + roomId + " without receiving an event"
        );
        continue;
      }

      inviteEvent = await this.processEvent(inviteEvent);
      this.emitter.emit("tx_room_invite", this, roomId, inviteEvent);
    }

    // Process rooms we've joined and their events
    for (const roomId in joinedRooms) {
      if (this.lastJoinedRoomIds.indexOf(roomId) === -1) {
        this.emitter.emit("tx_room_join", this, roomId);
        this.lastJoinedRoomIds.push(roomId);
      }

      const room = joinedRooms[roomId];

      if (room.account_data && room.account_data.events) {
        for (const event of room.account_data.events) {
          this.emitter.emit("tx_room_account_data", this, roomId, event);
        }
      }

      if (!room.timeline || !room.timeline.events) continue;

      for (let event of room.timeline.events) {
        event = await this.processEvent(event);

        // if (
        //   event.type === "m.room.encrypted" &&
        //   (await this.crypto?.isRoomEncrypted(roomId))
        // ) {
        //   // await emitFn("room.encrypted_event", roomId, event);
        //   this.emitter.emit("tx_room_encrypted_event", this, roomId, event);
        //   try {
        //     event = (
        //       await this.crypto.decryptRoomEvent(
        //         new EncryptedRoomEvent(event),
        //         roomId
        //       )
        //     ).raw;
        //     event = await this.processEvent(event);
        //     // await emitFn("room.decrypted_event", roomId, event);
        //     this.emitter.emit("tx_room_decrypted_event", this, roomId, event);
        //   } catch (e) {
        //     log.error(
        //       "MatrixClient",
        //       `Decryption error on ${roomId} ${event["event_id"]}`,
        //       e
        //     );

        //     // await emitFn("room.failed_decryption", roomId, event, e);
        //     this.emitter.emit(
        //       "tx_room_failed_decryption",
        //       this,
        //       roomId,
        //       event,
        //       e
        //     );
        //   }
        // }

        if (event.type === "m.room.message") {
          this.emitter.emit("tx_room_message", this, roomId, event);
        }

        if (event.type === "m.room.tombstone" && event.state_key === "") {
          this.emitter.emit("tx_room_archived", this, roomId, event);
        }

        if (
          event.type === "m.room.create" &&
          event.state_key === "" &&
          event.content &&
          event.content.predecessor &&
          event.content.predecessor.room_id
        ) {
          this.emitter.emit("tx_room_upgraded", this, roomId, event);
        }

        this.emitter.emit("tx_room_event", this, roomId, event);
      }
    }
  }

  /**
   * Listens for new events on `/sync` with a timeout based on `syncTimeout`
   * This method is looped automatically when `start()` is called
   * @param since token used to sync events from a specific point in time
   */
  protected async doSync(since: string | null | undefined) {
    const response = await this.makeRequest<IMatrixSyncResponse>(
      MX_API_HEAD + "/sync",
      {
        query: [
          "full_state=false",
          "timeout=" + Math.max(0, this.syncingTimeout),
          since ? "since=" + since : "",
          "filter=" + this.filterId,
        ],
      }
    );

    return response;
  }

  private async processEvent(event: any): Promise<any> {
    if (!event) return event;

    if (!this.eventProcessors[event.type]) return event;

    for (const processor of this.eventProcessors[event.type]) {
      await processor.processEvent(event, this, EventKind.RoomEvent);
    }

    return event;
  }

  /**
   * Sends an event to the given room
   * @param {string} roomId the room ID to send the event to
   * @param {string} eventType the type of event to send
   * @param {string} content the event body to send
   * @returns {Promise<string>} resolves to the event ID that represents the event
   */
  public async sendEvent(
    roomId: string,
    eventType: string,
    content: any
  ): Promise<string> {
    const txnId = new Date().getTime() + "__REQ" + ++this.requestId;
    // console.log("content", content);
    const resp: any = await this.makeRequest(
      MX_API_HEAD +
        "/rooms/" +
        encodeURIComponent(roomId) +
        "/send/" +
        encodeURIComponent(eventType) +
        "/" +
        encodeURIComponent(txnId),
      {
        method: "PUT",
        body: content,
      }
    );

    // console.log("sendEvent", resp);

    return resp.event_id;
  }

  /**
   * Sends a message to the given room
   * @param {string} roomId the room ID to send the message to
   * @param {object} content the event content to send
   * @returns {Promise<string>} resolves to the event ID that represents the message
   */

  public async sendMessage(roomId: string, content: any): Promise<string> {
    return this.sendEvent(roomId, "m.room.message", content);
  }

  /**
   * Sends a state event to the given room
   * @param {string} roomId the room ID to send the event to
   * @param {string} type the event type to send
   * @param {string} stateKey the state key to send, should not be null
   * @param {string} content the event body to send
   * @returns {Promise<string>} resolves to the event ID that represents the message
   */
  public async sendStateEvent(
    roomId: string,
    type: string,
    stateKey: string,
    content: any
  ): Promise<string> {
    return this.makeRequest(
      MX_API_HEAD +
        "/rooms/" +
        encodeURIComponent(roomId) +
        "/state/" +
        encodeURIComponent(type) +
        "/" +
        encodeURIComponent(stateKey),
      { method: "PUT", body: content }
    ).then((response: any) => {
      // console.log("sendStateEvent", response);
      return response.event_id;
    });
  }

  /**
   * Gets the current user ID for this client
   * @returns {Promise<string>} The user ID of this client
   */
  public async getUserId(): Promise<string> {
    if (this.userId) return Promise.resolve(this.userId);

    const response = await this.getWhoAmI();
    this.userId = response.user_id;
    return this.userId;
  }

  /**
   * Returns `MatrixUserProfileResponse` containing the current display name of the userId
   * ```ts
   * const profile = await client.getUserProfile(event.sender);
   * ```
   * @param userId ID of the user to retrieve the profile
   */
  public async getUserProfile(userId: string) {
    const d = await this.makeRequest<MatrixProfileInfo>(
      MX_API_HEAD + "/profile/" + userId
    );

    const displayName = d.displayname;

    const avatarUrl = getHttpUriForMxc(
      this.homeserverUrl,
      d.avatar_url!,
      M_AVATAR_WIDTH,
      M_AVATAR_HEIGHT,
      "scale"
    );

    return { displayName, avatarUrl };
  }

  /**
   * Returns `IMatrixRoomStateResponse` of the given room id containing the current display name
   * @param roomId ID of Room to get the display name
   */
  public async getRoomStateName(roomId: string) {
    return await this.makeRequest<RoomNameEventContent>(
      MX_API_HEAD +
        "/rooms/" +
        encodeURIComponent(roomId) +
        "/state/m.room.name/"
    );
  }

  /**
   * Returns `RoomAvatarEventContent` of the given room id containing the current display name
   * @param roomId ID of Room to get the display name
   */
  public async getRoomStateAvatar(roomId: string) {
    return await this.makeRequest<RoomAvatarEventContent>(
      MX_API_HEAD +
        "/rooms/" +
        encodeURIComponent(roomId) +
        "/state/m.room.avatar/"
    );
  }

  // /**
  //  * Returns `TutorwixCommandEventContent` of the given room id & stateKey (command key)
  //  * @param roomId ID of Room to get the display name
  //  */
  // public async getRoomStateCommand(roomId: string, stateKey: string) {
  //   let eventType = TutorwixEventType.command;

  //   return await this.makeRequest<TutorwixCommandEventContent>(
  //     // MX_API_HEAD + "/rooms/" + encodeURIComponent(roomId) + "/state/" + encodeURIComponent(eventType) + "/"
  //     `${MX_API_HEAD}/rooms/${encodeURIComponent(roomId)}/state/${encodeURIComponent(
  //       eventType
  //     )}/${stateKey}`
  //   );
  // }

  //
  /**
   * Returns `RoomTopicEventContent` of the given room id containing the current display name
   * @param roomId ID of Room to get the display name
   */
  public async getRoomStateTopic(roomId: string) {
    return await this.makeRequest<RoomTopicEventContent>(
      MX_API_HEAD +
        "/rooms/" +
        encodeURIComponent(roomId) +
        "/state/m.room.topic/"
    );
  }

  /**
   * Stops the client from syncing.
   */
  public stop() {
    this.stopSyncing = true;
  }

  /**
   * Starts syncing the client with an optional filter
   * @param {any} filter The filter to use, or null for none
   * @returns {Promise<any>} Resolves when the client has started syncing
   */
  public async start(isSnapshot: boolean, filter?: any): Promise<any> {
    this.stopSyncing = false;

    if (!filter || typeof filter !== "object") {
      log.trace(
        "MatrixClient",
        "No filter given or invalid object - using defaults."
      );
      filter = null;
    }

    const userId = await this.getUserId();
    let createFilter = false;
    let existingFilter = await Promise.resolve(this.localStore.getFilter());
    if (existingFilter) {
      log.trace(
        "MatrixClient",
        "Found existing filter. Checking consistency with given filter"
      );

      if (JSON.stringify(existingFilter.filter) === JSON.stringify(filter)) {
        log.trace("MatrixClient", "Filters match");
        this.filterId = existingFilter.id;
      } else {
        createFilter = true;
      }
    } else {
      createFilter = true;
    }

    console.log("createFilter", createFilter);

    if (createFilter && filter) {
      log.trace("MatrixClientLite", "Creating new filter");
      let response: any = await this.makeRequest(
        MX_API_HEAD + "/user/" + encodeURIComponent(userId) + "/filter",
        { method: "POST", body: JSON.stringify(filter) }
      );
      this.filterId = response["filter_id"];
      this.localStore.setSyncToken(null);
      this.localStore.setFilter({
        id: this.filterId,
        filter: filter,
      });
    }

    log.trace("MatrixClient", "Starting sync with filter ID " + this.filterId);

    let lastSync = isSnapshot ? null : await this.localStore.getSyncToken()!;

    this.startSyncLoop(lastSync);
  }

  /**
   * Gets the user's information from the server directly.
   * @returns {Promise<IWhoAmI>} The "who am I" response.
   */
  public async getWhoAmI(): Promise<IWhoAmI> {
    return this.makeRequest<IWhoAmI>(MX_API_HEAD + "/account/whoami");
  }

  /**
   * Returns the `IMatrixJoinedRoomsResponse` containing a Map of all joined roomId's
   */
  public async joinedRooms() {
    return await this.makeRequest<IMatrixJoinedRoomsResponse>(
      MX_API_HEAD + "/joined_rooms"
    );
  }

  /**
   * Sends a notice to the given room
   * @param {string} roomId the room ID to send the notice to
   * @param {string} text the text to send
   * @returns {Promise<string>} resolves to the event ID that represents the message
   */

  public async sendNotice(roomId: string, text: string): Promise<string> {
    return this.sendMessage(roomId, {
      body: text,
      msgtype: "m.notice",
    });
  }

  /**
   * Sends a text message to the given room
   * @param {string} roomId the room ID to send the text to
   * @param {string} text the text to send
   * @returns {Promise<string>} resolves to the event ID that represents the message
   */

  public async sendText(roomId: string, text: string): Promise<string> {
    return this.sendMessage(
      roomId,
      JSON.stringify({
        body: text,
        msgtype: "m.text",
      })
    );
  }

  /**
   * Sends a text message to the given room with HTML content
   * @param {string} roomId the room ID to send the text to
   * @param {string} html the HTML to send
   * @returns {Promise<string>} resolves to the event ID that represents the message
   */

  public async sendHtmlText(roomId: string, html: string): Promise<string> {
    return this.sendMessage(roomId, {
      body: htmlToText(html, { wordwrap: false }),
      msgtype: "m.text",
      format: "org.matrix.custom.html",
      formatted_body: html,
    });
  }

  /**
   * Returns `MatrixRoomMembersResponse` containing the current members of the room
   * ```ts
   * const profile = await client.getMembersByRoomId(roomId);
   * ```
   * @param roomId ID of the room to retrieve the members
   */

  public async getMembersByRoomId(roomId: string) {
    const endpoint = `${MX_API_HEAD}/rooms/${encodeURIComponent(
      roomId
    )}/joined_members`;

    const data: any = await this.makeRequest<{
      matrixId: string;
      displayName: string;
      avatarUrl: string;
    }>(endpoint);

    const members = Object.keys(data.joined).map((k) => {
      const avatarUrl = getHttpUriForMxc(
        this.homeserverUrl,
        data.joined[k]["avatar_url"],
        M_AVATAR_WIDTH,
        M_AVATAR_HEIGHT
        // "crop"
      );

      return {
        matrixId: k,
        displayName: data.joined[k]["display_name"],
        avatarUrl,
      };
    });

    return members;
  }

  /**
   * Uploads data to the homeserver's media repository.
   * @param {Buffer} data the content to upload.
   * @param {string} contentType the content type of the file. Defaults to application/octet-stream
   * @param {string} filename the name of the file. Optional.
   * @returns {Promise<string>} resolves to the MXC URI of the content
   */

  public async uploadContent(
    data: httpBodyType,
    filename: string = ""
  ): Promise<string> {
    const resp: any = this.makeRequest("_matrix/media/r0/upload", {
      query: ["filename=" + filename],
      method: "POST",
      body: data,
    });

    return resp;
  }

  public async getUserPresence(userId: string): Promise<PresenceEventContent> {
    const endpoint = `${MX_API_HEAD}/presence/${encodeURIComponent(
      userId
    )}/status`;

    const data: PresenceEventContent =
      await this.makeRequest<PresenceEventContent>(endpoint);
    return data;
  }

  public async setUserPresence(userId: string): Promise<PresenceEventContent> {
    const endpoint = `${MX_API_HEAD}/presence/${encodeURIComponent(
      userId
    )}/status`;
    const body = JSON.stringify({ presence: "offline" });
    const data: PresenceEventContent =
      await this.makeRequest<PresenceEventContent>(endpoint, {
        method: "PUT",
        body,
      });
    return data;
  }

  public async upgradeRoom(roomId: string, new_version: string) {
    const endpoint = `${MX_API_HEAD}/rooms/${roomId}/upgrade`;

    const resp = await this.makeRequest(endpoint, {
      method: "POST",
      body: JSON.stringify({ new_version }),
    });

    console.log("upgradeRoom", resp);

    return resp;
  }
}
