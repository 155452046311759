import { createModel, RoutingState } from "@captaincodeman/rdx";
import { State, Store } from "../store";
import { createSelector } from "reselect";
import { gretch } from "gretchen";
import {
  ServiceUrls,
  ApiEndpoints,
  EventName,
  API_REQUEST_TIMEOUT,
} from "../../config";
import log from "loglevel";
import { UserRole } from "./usermanager";
import { RouteName } from "../config";

export interface ICloze {
  clozeId: string;
  clozeData?: IToken[];
}

export interface IToken {
  text_with_ws: string;
  index: number;
  lemma: string;
  is_target: boolean;
  pos: UniversalPosTag;
  cloze_options: string[];
  answer_index: number;
}

export enum UniversalPosTag {
  ADJ = "ADJ",
  ADP = "ADP",
  ADV = "ADV",
  AUX = "AUX",
  CONJ = "CONJ",
  DET = "DET",
  INTJ = "INTJ",
  NOUN = "NOUN",
  NUM = "NUM",
  PART = "PART",
  PRON = "PRON",
  PROPN = "PROPN",
  PUNCT = "PUNCT",
  SCONJ = "SCONJ",
  SYM = "SYM",
  VERB = "VERB",
  X = "X",
}

export interface IQuiz {
  id: string;
  quiz_id: string;
  display_title: string;
  quiz: IQuizlet[];
}

export interface IQuizlet {
  quizletId: number;
  question: string;
  choices: string[];
  answer: string;
  band: string;
}

export interface IIceServers {
  iceServers: [
    {
      username: string;
      urls: string[];
      credential: string;
    }
  ];
}

export interface IPDFDesc {
  pdfURL: string;
  title: string;
  description?: string;
}

export enum SUBJECT {
  English = "English",
  French = "French",
  Latin = "Latin",
  Mathematics = "Mathematics",
  Chemistry = "Chemistry",
  Physics = "Physics",
  Biology = "Biology",
  Geography = "Geography",
}

export interface ICourseInfo {
  courseId: string;
  courseTitle: string;
  author?: string;
  narrator?: string;
  coverImage?: string;
  language?: string;
  subject?: string;
  topic?: string;
  description?: string;
}

export interface IMediaDirectory {
  id: string;
  name: string;
}

export interface IWordcast {
  header: string;
  sense: string;
  id?: string;
  mediaId?: string;
  pos?: UniversalPosTag;
  sentence_in_context?: string;
  images?: string[];
  examples?: string[];
}

export interface ITranscript {
  block_id: number;
  speaker: string;
  start_time: number;
  end_time: number;
  text: string;
}

export interface IMediaInfo {
  mediaId: string;
  title: string;
  mediaUrl?: string;
  filename?: string;
  isVideo?: boolean;
  language?: string;
  courseId?: string;
  courseTitle?: string;
  duration?: number;
  mediaBinary?: Buffer;
  wordcast?: IWordcast[];
  tokenizedText?: IToken[];
  transcript?: ITranscript[];
}

export interface IMediaList {
  courseId: string;
  mediaInfo: IMediaInfo[];
}

export interface IResourceState {
  iceServers: IIceServers | undefined;
  quizlist?: IQuiz[];
  pdfList: IPDFDesc[];
  mediaDirectoryList: IMediaDirectory[];
  courseList: ICourseInfo[];
  mediaList: IMediaList[];
  activeMedia: IMediaInfo | undefined;
  activatedMediaList: IMediaInfo[];
  currentMediaList: IMediaInfo[] | undefined;
  availableMediaList: IMediaInfo[];
  fetchMediaListCompleted: boolean;
}

const initialState: IResourceState = {
  iceServers: undefined,
  pdfList: [],
  mediaDirectoryList: [],
  courseList: [],
  mediaList: [],
  activeMedia: undefined,
  activatedMediaList: [],
  currentMediaList: [],
  availableMediaList: [],
  fetchMediaListCompleted: false,
};

const getState = (state: State) => state.resource;

export default createModel({
  state: initialState,

  reducers: {
    setIceServers(state: IResourceState, iceServers: IIceServers) {
      return {
        ...state,
        iceServers,
      };
    },

    loadQuizList(state: IResourceState, quizlist: IQuiz[]) {
      return {
        ...state,
        quizlist,
      };
    },

    loadPDFList(state, payload) {
      return {
        ...state,
        pdfList: payload,
      };
    },

    loadMediaList(state, mediaList: IMediaList[]) {
      return {
        ...state,
        mediaList,
      };
    },

    loadCourseList(state, payload: ICourseInfo[]) {
      return {
        ...state,
        courseList: payload,
      };
    },

    setMediaDirectoryList(state, mediaDirectoryList: IMediaDirectory[]) {
      return {
        ...state,
        mediaDirectoryList,
      };
    },

    setAvailableMediaList(
      state,
      payload: {
        availableMediaList: IMediaInfo[];
        fetchMediaListCompleted: boolean;
      }
    ) {
      return {
        ...state,
        availableMediaList: payload.availableMediaList,
        fetchMediaListCompleted: payload.fetchMediaListCompleted,
      };
    },

    setCurrentMediaList(state, currentMediaList: IMediaInfo[] | undefined) {
      return {
        ...state,
        currentMediaList,
      };
    },

    activateMedia(state, activeMedia: IMediaInfo) {
      return {
        ...state,
        activeMedia,
      };
    },

    addActivatedMedia(state, activatedMediaList: IMediaInfo[]) {
      return {
        ...state,
        activatedMediaList,
      };
    },

    errored(state, err) {
      return {
        ...state,
        error: err,
      };
    },

    reset() {
      return initialState;
    },
  },

  effects(store: Store) {
    const dispatch = store.getDispatch();

    return {
      async fetchNewlyAddedMediaList() {
        const user = store.getState().usermanager.user;
        if (!user) return;
        const isAdmin = store.getState().usermanager.isAdmin;
        if (!isAdmin) return;
        if (!user.token) return;

        dispatch.resource.setAvailableMediaList({
          availableMediaList: [],
          fetchMediaListCompleted: false,
        });

        const r = store.getState().routing;
        const courseId: string =
          r.page === RouteName.courseDetail ? r.params.courseId : "";

        if (!courseId) return;

        const api = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.getNewlyAddedMediaList}?access_token=${user.token}&courseId=${courseId}`;

        try {
          const { url, status, error, data, response } = await gretch(api, {
            method: "GET",
            retry: {
              attempts: 3,
              methods: ["GET"],
            },
            timeout: API_REQUEST_TIMEOUT,
            headers: {
              origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
            },
          }).json();

          data.sort((a: IMediaInfo, b: IMediaInfo) =>
            a.title.localeCompare(b.title)
          );
          // console.log("status", status, typeof status);
          if (status === 200) {
            dispatch.resource.setAvailableMediaList({
              availableMediaList: data,
              fetchMediaListCompleted: true,
            });
          }
        } catch (err) {
          log.error(err);
        }
      },

      async fetchMediaDirectories() {
        const user = store.getState().usermanager.user;
        if (!user) return;
        const isAdmin = store.getState().usermanager.isAdmin;
        if (!isAdmin) return;
        if (!user.token) return;

        const api = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.getMediaDirectories}?access_token=${user.token}`;

        try {
          const { url, status, error, data, response } = await gretch(api, {
            method: "GET",
            retry: {
              attempts: 3,
              methods: ["GET"],
            },
            timeout: API_REQUEST_TIMEOUT,
            headers: {
              origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
            },
          }).json();

          data.sort((a: IMediaDirectory, b: IMediaDirectory) =>
            a.name.localeCompare(b.name)
          );

          dispatch.resource.setMediaDirectoryList(data);
        } catch (err) {
          log.error(err);
        }
      },

      async fetchCourseList() {
        const state = store.getState();
        const user = state.usermanager.user;
        if (!user) return;
        const isAdmin = state.usermanager.isAdmin;
        if (!isAdmin) return;
        if (!user.token) return;

        if (state.resource.courseList.length > 0) return;

        const api = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.getCourseList}?access_token=${user.token}`;

        try {
          const { url, status, error, data, response } = await gretch(api, {
            method: "GET",
            retry: {
              attempts: 3,
              methods: ["GET"],
            },
            timeout: API_REQUEST_TIMEOUT,
            headers: {
              origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
            },
          }).json();

          data.sort((a: ICourseInfo, b: ICourseInfo) =>
            a.courseTitle.localeCompare(b.courseTitle)
          );
          dispatch.resource.loadCourseList(data);
        } catch (err) {
          log.error(err);
        }
      },

      async fetchMediaList(payload: { courseId: string; reload: boolean }) {
        const { courseId, reload } = payload;
        const state = store.getState();
        const user = state.usermanager.user;
        if (!user) return;
        const isAdmin = state.usermanager.isAdmin;
        if (!isAdmin) return;
        if (!user.token) return;

        const mediaList = state.resource.mediaList;
        const current = mediaList.find((x) => x.courseId === courseId);

        if (current !== undefined && !reload) {
          dispatch.resource.setCurrentMediaList(current.mediaInfo);
          return;
        }

        const api = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.getMediaList}?access_token=${user.token}&courseId=${courseId}`;

        // console.log("reload", reload);

        try {
          const { status, error, data } = await gretch(api, {
            method: "GET",
            retry: {
              attempts: 3,
              methods: ["GET"],
            },
            timeout: API_REQUEST_TIMEOUT,
            headers: {
              origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
            },
          }).json();

          if (!data)
            throw new Error(
              `Error fetchMediaList: data undefined --- status: ${status}; error: ${error}; data: ${data}`
            );

          data.sort((a: IMediaInfo, b: IMediaInfo) =>
            a.title.localeCompare(b.title)
          );

          mediaList.push({ courseId, mediaInfo: data });

          dispatch.resource.loadMediaList(mediaList);
          dispatch.resource.setCurrentMediaList(data);
        } catch (err) {
          log.error(err);
        }
      },

      async reloadMediaList() {
        const r = store.getState().routing;
        const courseId: string =
          r.page === RouteName.courseDetail ? r.params.courseId : "";

        if (!courseId) return;

        dispatch.resource.fetchMediaList({ courseId, reload: true });
      },

      async loadActivatedMedia(mediaId: string) {
        const state = store.getState();
        const user = state.usermanager.user;
        if (!user) return;
        const isAdmin = state.usermanager.isAdmin;
        if (!isAdmin) return;
        if (!user.token) return;

        const activatedMediaList = state.resource.activatedMediaList;

        const activeMedia = activatedMediaList.find(
          (x) => x.mediaId === mediaId
        );

        if (activeMedia !== undefined) {
          dispatch.resource.activateMedia(activeMedia);
        }

        const api = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.getActivatedMedia}?access_token=${user.token}&mediaId=${mediaId}`;

        try {
          const { status, error, data, response } = await gretch(api, {
            method: "GET",
            retry: {
              attempts: 3,
              methods: ["GET"],
            },
            timeout: API_REQUEST_TIMEOUT,
            headers: {
              origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
            },
          }).json();

          if (!data)
            throw new Error(
              `Error loadActivatedMedia --- data: ${data}; response:${response}, response Type: ${response.type} ; body:  ${response.body}`
            );

          dispatch.resource.activateMedia(data);

          activatedMediaList.push(data);
          dispatch.resource.addActivatedMedia(activatedMediaList);
          // dispatch.resource.loadClozeData({ clozeId: mediaId });
        } catch (err) {
          log.error(err);
        }
      },

      async fetchPDFList(courseId: string) {
        const state = store.getState();
        const user = state.usermanager.user;
        if (!user) return;
        const isAdmin = state.usermanager.isAdmin;
        if (!isAdmin) return;
        if (!user.token) return;

        const api = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.getPdfList}?access_token=${user.token}&courseId=${courseId}`;

        try {
          const { data } = await gretch(api, {
            method: "GET",
            retry: {
              attempts: 3,
              methods: ["GET"],
            },
            timeout: API_REQUEST_TIMEOUT,
            headers: {
              origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
            },
          }).json();

          data!.sort((a: IPDFDesc, b: IPDFDesc) =>
            a.title.localeCompare(b.title)
          );

          dispatch.resource.loadPDFList(data);
        } catch (err) {
          log.error(err);
        }
      },

      async fetchQuizList(courseId: string) {
        const state = store.getState();
        const user = state.usermanager.user;
        if (!user) return;
        const isAdmin = state.usermanager.isAdmin;
        if (!isAdmin) return;
        if (!user.token) return;

        const api = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.getQuizList}?access_token=${user.token}&courseId=${courseId}`;

        try {
          const { data } = await gretch(api, {
            method: "GET",
            retry: {
              attempts: 3,
              methods: ["GET"],
            },
            timeout: API_REQUEST_TIMEOUT,
            headers: {
              origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
            },
          }).json();

          data.sort((a: IQuiz, b: IQuiz) =>
            a.display_title.localeCompare(b.display_title)
          );

          dispatch.resource.loadQuizList(data);
        } catch (err) {
          log.error(err);
        }
      },

      // async loadQuizData(quizId: string) {
      //   const authtoken = store.getState().usermanager.user?.token;
      //   const api = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.getQuizData}?access_token=${authtoken}&quizId=${quizId}`;

      //   try {
      //     const { data } = await gretch(api, {
      //       method: "GET",
      //       headers: {
      //         origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
      //       },
      //     }).json();

      //     if (!data) return;

      //     const { display_title, quiz, quiz_id } = data;

      //     dispatch.quizmanager.activate({
      //       quizId: quiz_id,
      //       displayTitle: display_title,
      //       quizzes: quiz,
      //       maxCount: quiz.length,
      //       currentQuizlet: quiz[0],
      //     });
      //   } catch (err) {
      //     log.error(err);
      //   }
      // },

      // async loadClozeData(cloze: ICloze) {
      //   const authtoken = store.getState().usermanager.user?.token;
      //   const api = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.getClozeData}?access_token=${authtoken}&clozeId=${cloze.clozeId}`;

      //   try {
      //     const { data } = await gretch(api, {
      //       method: "GET",
      //       headers: {
      //         origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
      //       },
      //     }).json();

      //     // if (!data || !data.clozeData) return;

      //     if (!data) return;

      //     // if (!data.clozeData || data.clozeData.length < 1) {
      //     //   dispatch.resource.createClozeData(cloze);
      //     // } else {
      //     cloze.clozeData = data.clozeData;
      //     dispatch.clozemanager.loadCloze(cloze);
      //     const targets = cloze.clozeData!.filter((x) => x.is_target);
      //     dispatch.clozemanager.setTargets(targets);
      //     // }
      //   } catch (err) {
      //     log.error(err);
      //   }
      // },

      async getIceDetails() {
        const state = store.getState();
        const user = state.usermanager.user;
        if (!user) return;
        const isAdmin = state.usermanager.isAdmin;
        if (!isAdmin) return;
        if (!user.token) return;

        const api =
          ServiceUrls.API_BASE_URL +
          "/" +
          ApiEndpoints.janusParams +
          `?access_token=${user.token}`;

        try {
          const { error, data, status, url } = await gretch(api, {
            method: "GET",
            headers: {
              origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
            },
          }).json();

          dispatch.resource.setIceServers(data);
        } catch (err) {
          log.error(err);
        }
      },

      async updateCourseDetail(payload: { courseId: string; updates: any }) {
        console.log("updateCourseDetail", payload); //not implemented
      },

      async addCourse(course: ICourseInfo) {
        const usr = store.getState().usermanager.user;
        if (!usr || usr.role !== UserRole.ADMIN) return;
        const access_token = usr.token;
        if (!access_token) return;

        const api = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.addCourse}?access_token=${access_token}`;
        const response = await fetch(api, {
          method: "POST",
          mode: "cors",
          headers: {
            origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
          },
          body: JSON.stringify(course),
        });

        if (response.ok) {
          // return response.json();
          console.log("addCourse", response.statusText, response.status);
        } else {
          log.error(`${response.status}`);
          // return {};
        }
      },

      async addWordcast(payload: IWordcast) {
        const usr = store.getState().usermanager.user;
        if (!usr || usr.role !== UserRole.ADMIN) return;
        const access_token = usr.token;
        if (!access_token) return;

        const activeMedia = store.getState().resource.activeMedia;
        if (!activeMedia) return;

        const mediaId = activeMedia.mediaId;
        const wordcast: IWordcast = {
          ...payload,
          images: payload.images?.filter((x) => x !== ""),
          mediaId,
        };
        console.log("addWordcast", payload, wordcast);

        const api = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.addWordcast}?access_token=${access_token}&mediaId=${mediaId}`;
        const response = await fetch(api, {
          method: "POST",
          mode: "cors",
          headers: {
            origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
          },
          body: JSON.stringify(wordcast),
        });

        if (response.ok) {
          // return response.json();
          console.log("addCourse", response.statusText, response.status);
        } else {
          log.error(`${response.status}`);
          // return {};
        }
      },

      async createClozeData(cloze: ICloze) {
        dispatch.socket.sendMessage([
          EventName.wsrpc,
          { method: "createClozeData", params: [cloze.clozeId] },
        ]);
      },

      async addUpdateMediaRecord({ mediaId: string, overwrite: boolean }) {
        const usr = store.getState().usermanager.user;
        if (!usr || usr.role !== UserRole.ADMIN) return;
        const access_token = usr.token;
        if (!access_token) return;

        const api = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.addUpdateMediaRecord}?access_token=${access_token}`;
        const response = await fetch(api, {
          method: "POST",
          mode: "cors",
          headers: {
            origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
          },
          body: JSON.stringify({ mediaId: string, overwrite: boolean }),
        });

        if (response.ok) {
          // return response.json();
          console.log(
            "addUpdateMediaRecord",
            response.statusText,
            response.status
          );
        } else {
          log.error(`${response.status}`);
          // return {};
        }
      },
    };
  },
});

export namespace ResourceSelectors {
  export const quizlist = createSelector([getState], (state) => state.quizlist);

  export const pdfList = createSelector([getState], (state) => state.pdfList);

  export const mediaDirectories = createSelector(
    [getState],
    (state) => state.mediaDirectoryList
  );

  export const courseList = createSelector(
    [getState],
    (state) => state.courseList
  );

  export const mediaList = createSelector(
    [getState],
    (state) => state.mediaList
  );

  export const currentMediaList = createSelector(
    [getState],
    (state) => state.currentMediaList
  );

  export const availableMediaList = createSelector(
    [getState],
    (state) => state.availableMediaList
  );

  export const fetchMediaListCompleted = createSelector(
    [getState],
    (state) => state.fetchMediaListCompleted
  );

  export const activeMedia = createSelector(
    [getState],
    (state) => state.activeMedia
  );

  export const wordcast = createSelector(
    [getState],
    (state) => state.activeMedia?.wordcast
  );

  export const mediaId = createSelector(
    [getState],
    (state) => state.activeMedia?.mediaId
  );

  export const isVideo = createSelector(
    [getState],
    (state) => state.activeMedia?.isVideo
  );

  export const title = createSelector(
    [getState],
    (state) => state.activeMedia?.title
  );

  export const mediaUrl = createSelector(
    [getState],
    (state) => state.activeMedia?.mediaUrl
  );

  export const transcript = createSelector(
    [getState],
    (state) => state.activeMedia?.transcript
  );

  export const wordcasts = createSelector(
    [getState],
    (state) => state.activeMedia?.wordcast
  );
}
