import { MX_API_HEAD } from "./models/constants";

import { ServiceUrls } from "../../config";

/**
 * Performs simple password login with the homeserver. The caller is
 * expected to confirm if the homeserver supports this login flow prior
 * to invocation.
 * @param {string} username The username (localpart or user ID) to log in with
 * @param {string} password The password for the account
 * @param {string} deviceName The name of the newly created device. Optional.
 * @returns {Promise<MatrixClient>} Resolves to a logged-in MatrixClient
 */
export async function passwordLogin(
  username: string,
  password: string,
  deviceName?: string
): Promise<{
  userId: string;
  deviceId: string;
  accessToken: string;
  homeserverUrl: string;
}> {
  const json = {
    type: "m.login.password",
    identifier: {
      type: "m.id.user",
      user: username,
    },
    password: password,
    initial_device_display_name: deviceName,
  };

  const response = await fetch(
    ServiceUrls.MX_HOMESERVER_BASE_URL + MX_API_HEAD + "/login",
    {
      method: "POST",
      body: JSON.stringify(json),
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );

  if (!response.ok) throw new Error("Fetched response NOT ok.");

  const { access_token, home_server, user_id, device_id, well_known } =
    await response.json();

  const accessToken = access_token;

  if (!accessToken)
    throw new Error("Expected access token in response - got nothing");

  let homeserverUrl = "https://" + home_server;
  const userId = user_id;
  const deviceId = device_id;

  if (
    well_known &&
    well_known["m.homeserver"] &&
    well_known["m.homeserver"].base_url
  ) {
    homeserverUrl = well_known["m.homeserver"].base_url;
  }

  return { userId, deviceId, accessToken, homeserverUrl };
}
