import { SlDialog } from "@shoelace-style/shoelace";
import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";

import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
  // requestAudioPermissions,
  // requestVideoPermissions,
} from "mic-check";

const enum DialogType {
  explanation = "explanation",
  systemDenied = "systemDenied",
  userDenied = "userDenied",
  trackError = "trackError",
}

@customElement("request-media-devices")
export class RequestMediaDevices extends LitElement {
  @property() appName: string = "This app";
  @state() dialogType: DialogType;

  @query("#mediadevices") mediadevices: SlDialog;
  @query("#dialog_close") closeButton: HTMLButtonElement;

  firstUpdated() {
    this.closeButton.addEventListener("click", () => this.mediadevices.hide());

    // Prevent the dialog from closing when the user clicks on the overlay
    this.mediadevices.addEventListener("sl-request-close", (evt: any) => {
      if (evt.detail.source === "overlay") {
        evt.preventDefault();
      }
    });
  }

  setShowDialog(dt: DialogType) {
    this.mediadevices.show();
  }

  async checkPermission() {
    return requestMediaPermissions()
      .then(() => {
        // dispatch.classroom.joinClassroom(roomId);
        return true;
      })
      .catch((error: MediaPermissionsError) => {
        if (error.type === MediaPermissionsErrorType.SystemPermissionDenied) {
          // user denied permission
          this.setShowDialog(DialogType.systemDenied);
        } else if (
          error.type === MediaPermissionsErrorType.UserPermissionDenied
        ) {
          // browser doesn't have access to devices
          this.setShowDialog(DialogType.userDenied);
          this.dialogType = DialogType.userDenied;
        } else if (
          error.type === MediaPermissionsErrorType.CouldNotStartVideoSource
        ) {
          // most likely when other apps or tabs are using the cam/mic (mostly windows)
          this.setShowDialog(DialogType.trackError);
        } else {
        }
        // setErrorDetails(error);

        return false;
      });
  }

  get htmlDialog() {
    if (this.dialogType === DialogType.userDenied) {
      return html` ${this.appName} requires access to your camera and
        microphone. Click the camera blocked icon:
        <img
          alt="icon"
          src=${"https://www.gstatic.com/meet/ic_blocked_camera_dark_f401bc8ec538ede48315b75286c1511b.svg"}
          style="display: 'inline'"
        />
        located at the right end of your browser's address bar. After granting
        access, logout then login again.`;
    } else if (this.dialogType === DialogType.systemDenied) {
      return html`${this.appName} Can't use your camera or microphone. Your
      browser might not have access to your camera or microphone. To fix this
      problem, open ...`;
    } else if (this.dialogType === DialogType.trackError) {
      return html`${this.appName} Can't start your camera or microphone. Another
      application (Zoom, Webex) or browser tab (Google Meet, Messenger Video)
      might already be using your webcam. Please turn off other cameras before
      proceeding.`;
    } else {
      return html`Allow ${this.appName} to use your camera and microphone so
      that other participants can see and hear you.`;
    }
  }

  render() {
    return html`
      <sl-dialog label="ATTENTION:" id="mediadevices" no-header>
        ${this.htmlDialog}

        <sl-button id="dialog_close" slot="footer" variant="primary"
          >Close</sl-button
        ></sl-dialog
      >
    `;
  }

  static get styles() {
    return [css``];
  }
}
