export function groupBy(arr: any[], criteria: any) {
  const newObj = arr.reduce(function (acc, currentValue) {
    if (!acc[currentValue[criteria]]) {
      acc[currentValue[criteria]] = [];
    }
    acc[currentValue[criteria]].push(currentValue);
    return acc;
  }, {});
  return newObj;
}

export const randomizeArray = (array: any[]) => {
  let currentIndex = array.length,
    temporaryValue: any,
    randomIndex: number;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

// we have an array of objects, we want to remove one object using only the id property
// const apps = [{id:34,name:'My App',another:'thing'},{id:37,name:'My New App',another:'things'}];

// export const removeElementFromArray = (item: any, arr: any[]) => {
//   const removeIndex = arr.findIndex((item) => item.id === 37);
//   // remove object
//   return arr.splice(removeIndex, 1);
// };

export const remove = (arr: any, func: any) =>
  Array.isArray(arr)
    ? arr.filter(func).reduce((acc, val) => {
        arr.splice(arr.indexOf(val), 1);
        return acc.concat(val);
      }, [])
    : [];

export const isEmptyArray = <T, _>(arr: T[]): boolean =>
  !Array.isArray(arr) || arr.length === 0;

export const sortBy = <T extends Record<string, any>, K extends keyof T>(
  arr: T[],
  k: K
): T[] => arr.concat().sort((a, b) => (a[k] > b[k] ? 1 : a[k] < b[k] ? -1 : 0));
