/* eslint-disable @typescript-eslint/no-non-null-assertion */
export type httpBodyType =
  | string
  | Blob
  | ArrayBufferView
  | ArrayBuffer
  | FormData
  | ReadableStream<Uint8Array>
  | URLSearchParams
  | null
  | undefined;

export type httpMethod = "GET" | "POST" | "PUT" | "DELETE" | null | undefined;

export async function doHttpRequest<T>(
  homeserverUrl: string,
  endpoint: string,
  options?: {
    query?: string[];
    method?: httpMethod;
    body?: httpBodyType;
    accessToken?: string;
    isRawResponse?: boolean;
  }
) {
  // console.log("doHttpRequest b4", homeserverUrl);
  const { query, method, body, accessToken, isRawResponse } = options!;

  const q = query
    ? "?" + (query!.length > 0 ? "&" : "") + query!.join("&")
    : "";
  const url =
    homeserverUrl + (homeserverUrl?.endsWith("/") ? "" : "/") + endpoint + q;

  const headers = new Headers();
  headers.append("Accept", "application/json");

  if (accessToken) headers.append("Authorization", `Bearer ${accessToken}`);

  const response = await fetch(url, {
    method: method ? method : "GET",
    body,
    headers,
  });

  const res = isRawResponse ? response : await response.json();

  return <T>res;
}
