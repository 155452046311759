export const StayCurrentPage = () => {
  history.pushState(null, document.title, location.href);
  window.addEventListener("popstate", (event) => {
    history.pushState(null, document.title, location.href);
  });
};

export const preventRefresh = () => {
  window.addEventListener("keyup", disableF5);
  window.addEventListener("keydown", disableF5);
  function disableF5(e) {
    if ((e.which || e.keyCode) == 116) e.preventDefault();
  }

  // // this only gives a warning, let user to decide...
  // window.addEventListener("beforeunload", (event) => {
  //   event.preventDefault();
  //   // Chrome requires returnValue to be set.
  //   event.returnValue = "";
  // });
};

export const disableContextMenu = () => {
  // prevent context menu right click
  window.addEventListener("contextmenu", (e) => e.preventDefault());
};

type URLParamValue = { [key: string]: string | string[] };

export const getURLParameters = (url: string) => {
  return (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce((a, v) => {
    const [key, value] = v.split("=");
    if (a[key]) {
      a[key] = (
        (typeof a[key] === "string" ? [a[key]] : a[key]) as string[]
      ).concat(value);
    } else {
      a[key] = value;
    }
    return a;
  }, {} as URLParamValue);
};

export const toggle = (ele: HTMLElement) => {
  const display = ele.style.display;
  ele.style.display = display === "none" ? "block" : "none";
};
