import { css } from "lit";

export const cssMediaBox = css`
  .wordcast_grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 0.25rem;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: rgb(var(--sl-color-neutral-500) / 5%);
  }

  .mediaview {
    display: grid;
  }

  .wordcast {
    overflow: hidden auto;
    margin: 0;
    padding: 1rem;
    background-color: rgb(var(--sl-color-neutral-500) / 10%);
  }

  .reading_text {
    color: white;
    text-align: left;
    line-height: 1.5rem;
    margin: 0;
    padding: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgb(var(--sl-color-neutral-500) / 20%);
  }
`;

export const cssMultimediaPlayer = css`
  .progress {
    display: flex;
    flex-flow: row;
    align-items: left;
    align-content: left;
    text-align: left;
    justify-content: space-around;
  }

  .percentage {
    position: relative;
    font-family: "Montserrat", sans-serif;
    font-size: 0.75rem;
    color: #1bdaf7;
  }

  /* style for current time of the track */
  .currentTime {
    position: relative;
    height: 20px;
    width: 20px;
    z-index: 4;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    color: rgba(168, 247, 247, 0.75);
  }

  /* style for the total length of the track */
  .durationTime {
    position: relative;
    height: 20px;
    width: 20px;
    z-index: 4;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    color: rgba(168, 247, 247, 0.75);
  }
  .course_title {
    color: #f2d2b6;
  }
  .media_title {
    color: #b6c4f2;
  }
  input[type="text"] {
    width: calc(100%-80px);
    height: 25px;
    padding: 6px;
    margin-top: 0px;
    font-size: 1rem;
    /* border: none; */
    background-color: rgba(160, 193, 242, 0.25);
    border: 1px solid rgba(241, 252, 151, 0.25);
  }

  .control-item {
    font-size: 1.25rem;
    color: white;
    cursor: pointer;
    z-index: 4;
  }
`;
