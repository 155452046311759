import { createModel } from "@captaincodeman/rdx";
import { State, Store } from "../store";
import { createSelector } from "reselect";
import { EventName, WEBSOCKET_CONNECTION_TIMEOUT } from "../../config";

export enum ConnectionState {
  CONNECTING,
  OPEN,
  CLOSING,
  CLOSED,
}

export enum PacketType {
  EVENT,
  ACK,
}

export interface IPacket {
  type: PacketType;
  data: any[];
  id?: number;
}

interface BaseSocketEvents {
  connect: "connect";
  reconnect: "reconnect";
  disconnect: "disconnect";
  reconnect_attempt: "reconnect_attempt";
  reconnect_error: "reconnect_error";
  reconnect_failed: "reconnect_failed";
}

export type SocketEvent = BaseSocketEvents[keyof BaseSocketEvents] | string;

const enc = new TextEncoder();
const dec = new TextDecoder();

const encodePacket = (packet: IPacket): ArrayBufferLike =>
  enc.encode(JSON.stringify(packet));

const decodePacket = (buffer: ArrayBuffer): IPacket =>
  JSON.parse(dec.decode(buffer).toString()) as IPacket;

export interface ISocketState {
  connectionState: ConnectionState;
  rpcResponse: any;
  messageIn: IPacket;
  messageOut: IPacket;
  error?: Error;
}

const initialState: ISocketState = {
  connectionState: ConnectionState.CLOSED,
  rpcResponse: {},
  messageIn: { type: PacketType.EVENT, data: [] },
  messageOut: { type: PacketType.EVENT, data: [] },
};

const getState = (state: State) => state.socket;

export default createModel({
  state: initialState,

  reducers: {
    connect(
      state: ISocketState,
      payload: {
        url: string;
        protocols?: string[];
        connectionState: ConnectionState;
      }
    ) {
      return {
        ...state,
        url: payload.url,
        protocols: payload.protocols,
        connectionState: payload.connectionState,
      };
    },

    setRpcResponse(state, rpcResponse: any) {
      return {
        ...state,
        rpcResponse,
      };
    },

    setIncomingMessage(state: ISocketState, messageIn: IPacket) {
      return {
        ...state,
        messageIn,
      };
    },

    setOutgoingMessage(state: ISocketState, messageOut: IPacket) {
      return {
        ...state,
        messageOut,
      };
    },

    errored(state: ISocketState, error: Error) {
      return {
        ...state,
        error,
      };
    },

    reset() {
      return initialState;
    },
  },

  effects(store: Store) {
    const dispatch = store.getDispatch();

    let socket: WebSocket | null = null;
    // let reconnectInterval: NodeJS.Timeout | null = null;
    // let reconnectCount: number = 0;
    // Keep track of the last connect payload we used to connect, so that when we automatically
    // try to reconnect, we can reuse the previous connect payload.
    let lastConnectPayload: {
      url: string;
      protocols: string[];
    } | null = null;

    // // Keep track of if the WebSocket connection has ever successfully opened.
    let hasOpened = false;

    let connectionTimeout: any | undefined;
    // let reconnectionTimeout: any | undefined;

    return {
      connectSocket(payload: {
        // isAudioOnly: boolean;
        url: string;
        protocols: string[];
      }) {
        const { url, protocols } = payload;
        // const { isAudioOnly, url, protocols } = payload;

        lastConnectPayload = { url, protocols };

        socket = new WebSocket(url, protocols);

        // Handle timeout
        connectionTimeout = setTimeout(() => {
          if (socket && socket.readyState === WebSocket.CONNECTING) {
            socket.close();
          }
        }, WEBSOCKET_CONNECTION_TIMEOUT);

        socket.addEventListener("open", () => {
          // //         // Clean up any outstanding reconnection attempts.
          // if (reconnectInterval) {
          //   clearInterval(reconnectInterval);
          //   reconnectInterval = null;
          //   reconnectCount = 0;
          // }

          const connectionState = socket?.readyState as ConnectionState;

          dispatch.socket.connect({
            url,
            protocols,
            connectionState,
          });

          hasOpened = true;

          if (connectionState === ConnectionState.OPEN) {
            // dispatch.classroom.openClass();
          }
        });

        socket.addEventListener("close", dispatch.socket.handleClose);
        socket.addEventListener("error", dispatch.socket.handleError);
        socket.addEventListener(
          "message",
          dispatch.socket.handleIncomingMessage
        );
      },

      sendMessage(data: any[]) {
        if (!socket) return;

        if (socket.readyState !== socket.OPEN) return;

        let msg: IPacket = { type: PacketType.EVENT, data };

        const { method, params } = data[1];

        if (data[0] === EventName.wsrpc) {
          let id = `${new Date().valueOf()}-${method.toString()}-${JSON.stringify(
            params
          )}`;

          msg.data[1].id = id;

          // acks.set(fnId, data[1]);
          // dispatch.socket.setACKS(acks);
          // msg.data[1].id = fnId;
          // fnId++;
          // dispatch.socket.setFnID(fnId);
        }

        socket.send(encodePacket(msg));

        dispatch.socket.setOutgoingMessage(msg);
      },

      handleIncomingMessage(event) {
        const msg =
          typeof event.data === "string"
            ? (JSON.parse(event.data) as IPacket)
            : decodePacket(event.data as ArrayBuffer);

        dispatch.socket.setIncomingMessage(msg);
        dispatch.socket.processWSRPCEvent(msg);
        // dispatch.socket.processPresenceEvent(msg);
        // dispatch.socket.processProseEditorEvent(msg);
      },

      processWSRPCEvent(msg) {
        const evt = msg["data"][0];
        const jsonData = msg["data"][1];

        switch (evt) {
          case EventName.wsrpc_response:
            dispatch.socket.setRpcResponse(jsonData);
            break;
          default:
        }
      },

      // processPresenceEvent(msg) {
      //   const evt = msg["data"][0];
      //   const jsonData = msg["data"][1];
      //   switch (evt) {
      //     case EventName.classroom_joined:
      //       const presence = { ...jsonData, isAdd: true };
      //       dispatch.classroom.updatePresence(presence);
      //       break;
      //     default:
      //   }
      // },

      // processProseEditorEvent(msg) {
      //   const evt = msg["data"][0];
      //   const jsonData = msg["data"][1];

      //   switch (evt) {
      //     case EventName.prose_editor_updated:
      //       dispatch.proseboard.update({
      //         proseEditorAuthority: jsonData,
      //       });
      //       break;
      //     default:
      //   }
      // },

      handleError() {
        dispatch.socket.errored(new Error(`rdx-websocket error`));

        //         // Conditionally attempt reconnection if enabled and applicable
        // const { reconnectOnError } = this.options;
        // if (reconnectOnError && this.canAttemptReconnect()) {
        //   this.handleBrokenConnection(dispatch);
        // }
      },

      handleClose() {
        // dispatch.socket.reset();

        // Stop connection timeout
        if (connectionTimeout) {
          clearTimeout(connectionTimeout);
        }
        // // Stop reconnection timeout
        // if (reconnectionTimeout) {
        //   clearTimeout(reconnectionTimeout);
        // }

        // if (hasOpened && lastConnectPayload && reconnectInterval == null) {
        //   dispatch.socket.connectSocket(lastConnectPayload);
        //   reconnectCount += 1;
        // }
      },

      disconnectSocket(code?: number, reason?: string) {
        dispatch.socket.sendMessage([EventName.disconnect]);
        if (socket) {
          socket.onclose = () => {};
          socket.close(
            code || 1000,
            reason || "WebSocket connection closed by rdx-websocket."
          );
          socket = null;
        } else {
          throw new Error(
            "WebSocket connection not initialized. connect to websocket first"
          );
        }

        dispatch.socket.reset();
      },
    };
  },
});

export namespace SocketSelectors {
  export const messageIn = createSelector(
    [getState],
    (state) => state.messageIn
  );

  export const messageOut = createSelector(
    [getState],
    (state) => state.messageOut
  );

  export const connectionState = createSelector(
    [getState],
    (state) => state.connectionState
  );
}
