import { SlButton, SlDrawer } from "@shoelace-style/shoelace";
import { css, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { Connected, State, RouteSelectors, dispatch } from "./connected";
import { getAspectRatio } from "../lib/utils/aspectratio";
import {
  cssAppLayout,
  cssMainGrid,
  cssResponsiveCardGrid,
  cssScrollbar,
  SharedStyles,
} from "../components/sharedStyles";
import { IWordcast, ResourceSelectors } from "../state/models/resource";

import { copyrighter } from "../config";
import "../components/wordcast-form";

@customElement("wordcast-page")
export class WordcastPage extends Connected {
  @state() wordcasts: IWordcast[] = [];
  @state() wordlet: string;
  @state() editMode: boolean = false;
  @state() targetWordlet: IWordcast;
  @state() aspectRatio: string;
  @state() page: string;

  @query("#addWordcastDrawer") addWordcastDrawer: SlDrawer;
  @query("#editWordcastDrawer") editWordcastDrawer: SlDrawer;
  @query("#mainboard_container") mainboard_container: HTMLDivElement;
  @query("sl-resize-observer") resizeObserver: any; // SlResizeObserver;

  mapState(state: State) {
    return {
      page: RouteSelectors.page(state),
      wordcasts: ResourceSelectors.wordcasts(state),
    };
  }

  protected firstUpdated() {
    this.resizeObserver.addEventListener("sl-resize", (event: CustomEvent) => {
      let containerWidth = Math.round(this.mainboard_container.clientWidth);
      let containerHeight = Math.round(this.mainboard_container.clientHeight);
      this.aspectRatio = getAspectRatio(containerWidth, containerHeight);
    });
  }

  onClickShowEditWordcastDrawer(e: PointerEvent) {
    const target = e.currentTarget as SlButton;
    this.editMode = true;
    this.targetWordlet = this.wordcasts.find((x) => x.id === target.id)!;
    this.editWordcastDrawer.show();
  }

  onEditWordcast(e: CustomEvent) {
    console.log("onEditWordcast", e.detail);
    console.log("targetWordlet", this.targetWordlet);
    // dispatch.resource.updateWordcast({ id:e.detail.id, updates });
    this.editWordcastDrawer.hide();
  }

  onClickAddWordcastDrawer() {
    this.editMode = false;
    this.addWordcastDrawer.show();
  }

  onAddWordcast(e: CustomEvent) {
    dispatch.resource.addWordcast(e.detail);
    this.addWordcastDrawer.hide();
  }

  get htmlWordcastCards() {
    if (!this.wordcasts)
      return html`<div class="centered">
        <h2>loading...</h2>
        <p>please activate current media</p>
        <sl-spinner
          style="font-size: 5rem;  --track-color: deepred; --indicator-color:yellow;"
        ></sl-spinner>
      </div>`;

    return html`${this.wordcasts.map(
      (x) => html`<sl-card class="card">
        <div slot="header">
          <sl-avatar class="button_icon"></sl-avatar>
          <span class="button_text">${x.header}</span>

          <sl-button
            id=${x.id}
            variant="text"
            size="small"
            @click=${this.onClickShowEditWordcastDrawer}
          >
            <sl-icon class="button_icon" slot="prefix" name="gear"></sl-icon>
            <span class="button_text">edit</span>
          </sl-button>

          <sl-drawer
            id="editWordcastDrawer"
            label="Edit Wordcast"
            placement="end"
            style="--size: calc(100vw - 2rem);"
          >
            <wordcast-form
              isEdit=${this.editMode}
              .wordcast=${this.targetWordlet}
              @edit_wordcast=${this.onEditWordcast}
            ></wordcast-form>
          </sl-drawer>
        </div>
        <ul>
          <li>
            <span class="button_icon">
              <sl-icon slot="prefix" name="person-bounding-box"></sl-icon></span
            ><span class="button_text">pos</span>
            ${x.pos}
          </li>

          <li>
            <span class="button_icon">
              <sl-icon slot="prefix" name="person-lines-fill"></sl-icon></span
            ><span class="button_text">sense </span>${x.sense}
          </li>
        </ul>

        ${Array.isArray(x.images) && x.images.length > 1
          ? html` <sl-details summary="images">
              <div class="images">
                ${x.images.map((x) => html` <img src=${x} />`)}
              </div>
            </sl-details>`
          : html``}

        <div slot="footer"></div>
      </sl-card>`
    )}`;
  }

  get htmlAddWordcast() {
    return html` <div class="header">
      <sl-button
        id="add_wordcast"
        variant="text"
        size="small"
        @click=${this.onClickAddWordcastDrawer}
        ><sl-icon class="button_icon" slot="prefix" name="person-plus"></sl-icon
        ><span class="button_text">add wordcast</span></sl-button
      >
      <sl-drawer
        id="addWordcastDrawer"
        label="Add Wordcast"
        placement="end"
        style="--size: calc(100vw - 2rem);"
      >
        <wordcast-form @add_wordcast=${this.onAddWordcast}></wordcast-form>
      </sl-drawer>
    </div>`;
  }

  render() {
    return html`
      <sl-resize-observer
        ><div class="main">
          <div class="searchbar">
            <sl-input
              type="search"
              placeholder="search wordcast"
              size="small"
              pill
              autofocus
              clearable
              ><sl-icon
                class="button_icon"
                name="search"
                slot="prefix"
              ></sl-icon
            ></sl-input>
          </div>
          ${this.htmlAddWordcast}
          <div id="mainboard_container" class="mainboard card-grid">
            ${this.htmlWordcastCards}
          </div>

          <div class="footsy footer__copyright">
            <p>&copy; ${copyrighter}</p>
          </div>
        </div></sl-resize-observer
      >
    `;
  }

  static get styles() {
    return [
      SharedStyles,
      cssScrollbar,
      cssAppLayout,
      cssMainGrid,
      cssResponsiveCardGrid,
      css``,
    ];
  }
}
