import { serialize } from "@shoelace-style/shoelace/dist/utilities/form";
import { css, html, LitElement } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { IUser, UserRole } from "../state/models/usermanager";

import { cssFormStyle } from "./sharedStyles";

@customElement("user-form")
export class UserForm extends LitElement {
  @property() user: IUser;
  @property() isEdit: boolean;
  @query("form") regform: HTMLFormElement;
  @query("#userid") userid: HTMLInputElement;

  private arrList: string[] = Object.values(UserRole);

  onSubmit(e) {
    e.preventDefault();
    const raw = serialize(this.regform);
    let entries = Object.entries(raw).filter(([key]) => raw[key] !== ""); // filter out empty input
    let detail = Object.fromEntries(entries);
    const evtName = this.isEdit ? "edit_user_account" : "register_new_user";
    try {
      this.dispatchEvent(
        new CustomEvent(evtName, {
          bubbles: true,
          composed: true,
          detail,
        })
      );
    } catch (err) {
      console.log(err); // TODO
    }
  }

  get htmlEditUser() {
    return html` <sl-input
        id="userid"
        name="username"
        value=${ifDefined(this.user?.username)}
        type="text"
        readonly
        required
      ></sl-input>

      <sl-input
        id="email"
        name="email"
        value=${ifDefined(this.user?.email)}
        type="email"
        placeholder="enter email address"
        clearable
      ></sl-input>

      <sl-input
        id="password"
        name="password"
        type="text"
        placeholder="enter password"
        clearable
      ></sl-input>

      <sl-select
        id="roles"
        name="role"
        value=${ifDefined(this.user?.role)}
        placeholder="select user role"
        size="small"
        placement="bottom"
        hoist
        clearable
      >
        ${this.arrList.map(
          (x) => html` <sl-menu-item value=${x}>${x}</sl-menu-item> `
        )}
      </sl-select>`;
  }

  get htmlCreateNewUser() {
    return html` <sl-input
        name="username"
        type="text"
        placeholder="enter username"
        required
        clearable
      ></sl-input>
      <sl-input
        id="password"
        name="password"
        type="text"
        placeholder="enter password"
        clearable
        required
      ></sl-input>
      <sl-select
        id="roles"
        name="role"
        value=${ifDefined(this.user?.role)}
        placeholder="select user role"
        size="small"
        placement="bottom"
        hoist
        clearable
        required
      >
        ${this.arrList.map(
          (x) => html` <sl-menu-item value=${x}>${x}</sl-menu-item> `
        )}
      </sl-select>`;
  }

  render() {
    return html`
      <div class="form_wrapper">
        <form class="form_body" @submit=${this.onSubmit}>
          ${this.isEdit ? this.htmlEditUser : this.htmlCreateNewUser}
          <sl-divider style="--spacing: 10rem;"></sl-divider>
          <sl-button type="submit" variant="default">Submit</sl-button>
        </form>
      </div>
    `;
  }

  static get styles() {
    return [cssFormStyle, css``];
  }
}
