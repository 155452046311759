import {
  devtools,
  // persist,
  createStore,
  StoreState,
  StoreDispatch,
  ModelStore,
  RoutingState,

  // Action,
} from "@captaincodeman/rdx";

import { config, RouteName } from "./config";

export const store = devtools(createStore(config)); //dev
// export const store = createStore(config); //prod

export const dispatch: Dispatch = store.dispatch;

export interface State extends StoreState<typeof config> {
  routing: RoutingState<RouteName>;
}

export interface Dispatch extends StoreDispatch<typeof config> {}

export interface Store extends ModelStore<Dispatch, State> {}
