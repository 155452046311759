import { LitElement, html, css } from "lit";
import { customElement, query } from "lit/decorators.js";
import { gsap } from "gsap";
import { svgLogo } from "./logo-svg";

@customElement("logo-wheel")
export class LogoWheel extends LitElement {
  @query("#teeth") teeth: any;
  @query("#arks") arks: any;
  @query("#penbook") penbook: any;
  @query("#tutorwix") tutorwix: any;
  @query("#logowheel") logowheel: any;

  static get styles() {
    return [
      css`
        .svg-container {
          display: inline-block;
          position: relative;
          width: 100%;
          height: 100%;
          padding-bottom: 100%;
          vertical-align: center;
          overflow: hidden;
        }
        .svg-content {
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
        }
      `,
    ];
  }

  render() {
    return html` <div id="logowheel" class="svg-container">${svgLogo}</div> `;
  }

  firstUpdated() {
    const tl1 = gsap.timeline();
    const duration = 1;

    tl1
      .to(this.logowheel, {
        duration,
        opacity: 1,
        scale: 0.05,
        transformOrigin: "center center",
        smoothOrigin: true,
        ease: "back.easeInOut",
      })
      .to(
        this.arks,
        {
          duration,
          ease: "back.easeOut",
          rotation: -360,
          transformOrigin: "center center",
        },
        "-=3"
      )
      .to(
        this.teeth,
        {
          duration,
          rotation: 360,
          transformOrigin: "center center",
          smoothOrigin: true,
          ease: "back.easeOut",
        },
        "-=1"
      )
      .to(
        this.logowheel,
        {
          duration,
          scale: 1,
          opacity: 1,
          ease: "power1.easeOut",
          transformOrigin: "center center",
          top: "10%",
        },
        "-=1"
      )
      .to(
        this.arks,
        {
          duration,
          scale: 1,
          opacity: 0.25,
          ease: "back.easeOut",
          transformOrigin: "center center",
        },
        "-=1"
      )
      .to(
        this.teeth,
        { duration, scale: 1, opacity: 0.15, ease: "back.easeOut" },
        "-=1"
      )
      .to(
        this.penbook,
        { duration, scale: 1, opacity: 0.05, ease: "back.easeOut" },
        "-=1"
      )
      .to(
        this.tutorwix,
        {
          duration,
          scale: 0.75,
          rotation: 720,
          transformOrigin: "center center",
          opacity: 0.25,
          ease: "power4.easeInOut",
        },
        "-=1"
      )
      .to(
        this.teeth,
        {
          duration,
          scale: 1,
          opacity: 0.05,
          ease: "back.easeOut",
          rotation: 360,
          transformOrigin: "center center",
        },
        "-=0"
      )
      .to(
        this.arks,
        {
          duration,
          scale: 1,
          opacity: 0.05,
          ease: "back.easeOut",
          rotation: 360,
          transformOrigin: "center center",
        },
        "-=0"
      );
  }
}
