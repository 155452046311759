import { SlSelect } from "@shoelace-style/shoelace";
import { serialize } from "@shoelace-style/shoelace/dist/utilities/form";
import { css, html, LitElement, PropertyValues } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { ICourseInfo, IMediaDirectory } from "../state/models/resource";
import { cssAppLayout, cssFormStyle } from "./sharedStyles";

@customElement("course-form")
export class CourseForm extends LitElement {
  @property() mediaDirectories: IMediaDirectory[];
  @property() course: ICourseInfo;
  @property() isEdit: boolean;
  @query("form") regform: HTMLFormElement;

  @state() private selectedCourseId: string | undefined;
  @state() private newCourseInfo: ICourseInfo;

  updated(changedProps: PropertyValues) {
    if (changedProps.has("mediaDirectories")) {
      console.log("mediaDirectories", this.mediaDirectories);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const raw = serialize(this.regform);
    const entries = Object.entries(raw).filter(([key]) => raw[key] !== ""); // filter out empty input
    const detail = Object.fromEntries(entries);
    this.newCourseInfo = { ...this.newCourseInfo, ...detail };
    const evtName = this.isEdit ? "edit_course_detail" : "add_new_course";

    try {
      this.dispatchEvent(
        new CustomEvent(evtName, {
          bubbles: true,
          composed: true,
          detail: this.newCourseInfo,
        })
      );
    } catch (err) {
      console.log(err); // TODO
    }
  }

  get htmlEditCourse() {
    return html`
      <sl-input
        id="course-id"
        name="courseId"
        value=${ifDefined(this.course?.courseId)}
        type="text"
        readonly
        required
      ></sl-input>

      <sl-input
        id="course-title"
        name="courseTitle"
        value=${ifDefined(this.course?.courseTitle)}
        type="text"
        style="width:100%"
      ></sl-input>
    `;
  }

  onCourseSelected(e: Event) {
    const target = <SlSelect>e.target;
    if (!target) return;

    let m = this.mediaDirectories.find(
      (x) => x.name === (target.value as string)
    );
    this.selectedCourseId = m?.id;

    if (this.selectedCourseId) {
      this.newCourseInfo = { courseId: this.selectedCourseId, courseTitle: "" };
    }
  }

  get htmlCreateNewCourse() {
    return html`
      <sl-select
        name="courseTitle"
        placeholder="select course title"
        size="small"
        style="width:100%;"
        placement="bottom"
        hoist
        clearable
        required
        @sl-change=${this.onCourseSelected}
      >
        ${this.mediaDirectories.map(
          (x) =>
            html`
              <sl-menu-item id=${x.id} value=${x.name}>${x.name}</sl-menu-item>
            `
        )}
      </sl-select>
    `;
  }

  render() {
    return html`
      <div class="form_warapper">
        <form class="form_body" @submit=${this.onSubmit}>
          ${this.isEdit ? this.htmlEditCourse : this.htmlCreateNewCourse}
          <sl-divider style="--spacing: 10rem;"></sl-divider>
          <sl-button type="submit" variant="default">Submit</sl-button>
        </form>
      </div>
    `;
  }

  static get styles() {
    return [cssAppLayout, cssFormStyle, css``];
  }
}
