import { createModel } from "@captaincodeman/rdx";
import { State, Store } from "../store";
import { createSelector } from "reselect";
import { ApiEndpoints, ServiceUrls } from "../../config";
import logger from "loglevel";
import { RouteName } from "../config";

export enum UserRole {
  TEACHER = "teacher",
  STUDENT = "student",
  ADMIN = "admin",
}

export interface IUser {
  id: number;
  username: string;
  role: UserRole;
  token?: string;
  matrixId?: string;
  email?: string;
  createdAt?: number;
  inactive?: boolean;
}

export interface IUserUpdatable {
  password?: string;
  email?: string;
  role: UserRole;
  inactive?: boolean;
}

// export type User = IUser;

export interface AuthState {
  user: IUser | null;
  isHost: boolean;
  isAdmin: boolean;
  users: IUser[];
  error: string;
}

const initialState: AuthState = {
  user: null,
  isHost: false,
  isAdmin: false,
  users: [],
  error: "",
};

export default createModel({
  state: initialState,

  reducers: {
    signedIn(state, user: IUser) {
      return {
        ...state,
        user: user,
        isHost: user.role === UserRole.TEACHER,
        isAdmin: user.role === UserRole.ADMIN,
      };
    },

    setUsers(state, users: IUser[]) {
      return {
        ...state,
        users,
      };
    },

    reset() {
      return initialState;
    },

    errored(state, err) {
      return {
        ...state,
        error: err,
      };
    },
  },

  effects(store: Store) {
    const dispatch = store.getDispatch();

    return {
      logout() {
        localStorage.clear();
        window.history.pushState({}, "", RouteName.home);
        location.reload();
      },

      async getUsers(limit: number) {
        const usr = store.getState().usermanager.user;
        if (!usr || usr.role !== UserRole.ADMIN) return;

        const access_token = usr.token;
        if (!access_token) return;

        const api = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.getUsers}?access_token=${access_token}&limit=${limit}`;
        const users = await fetchUsers(api);
        dispatch.usermanager.setUsers(users);
      },

      async getUserDetail(payload: { username: string }) {
        const usr = store.getState().usermanager.user;
        if (!usr || usr.role !== UserRole.ADMIN) return;
        const access_token = usr.token;
        if (!access_token) return;

        const api = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.getUserDetail}?access_token=${access_token}`;
        await fetchUserDetail(api, payload);
      },

      async updateUserAccount(payload: {
        username: string;
        updates: IUserUpdatable;
      }) {
        const usr = store.getState().usermanager.user;
        if (!usr || usr.role !== UserRole.ADMIN) return;
        const access_token = usr.token;
        if (!access_token) return;

        const { username, updates } = payload;

        const api = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.updateUserAccount}?access_token=${access_token}`;
        await updateUser(api, { username, updates });
      },

      async registerNewUser(payload: {
        username: string;
        password: string;
        role: UserRole;
      }) {
        const usr = store.getState().usermanager.user;
        if (!usr || usr.role !== UserRole.ADMIN) return;

        const access_token = usr.token;
        if (!access_token) return;

        const api = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.register}?access_token=${access_token}`;

        await registerUser(api, payload);
      },

      async signinEmailPassword(payload: {
        username: string;
        password: string;
      }) {
        if (store.getState().usermanager.user) return;
        const user: IUser = await signinPrimaryAuth(payload);

        if (user.role !== UserRole.ADMIN) {
          dispatch.usermanager.logout();
        }

        dispatch.usermanager.signedIn(user);
        dispatch.matrix.createSession(payload);
      },
    };
  },
});

const getState = (state: State) => state.usermanager;

export namespace UserManagerSelectors {
  export const users = createSelector([getState], (state) => state.users);

  export const user = createSelector([getState], (state) => state.user);

  export const username = createSelector([user], (user) =>
    user ? user.username : ""
  );

  export const authenticated = createSelector([user], (user) => user !== null);

  export const isAdmin = createSelector([user], (user) =>
    user ? user.role === UserRole.ADMIN : false
  );

  export const isHost = createSelector(
    [user],
    (user) => user?.role === UserRole.TEACHER
  );

  export const error = createSelector([getState], (state) => state.error);
}

const fetchUsers = async (api: string) => {
  try {
    const response = await fetch(api, {
      method: "GET",
      mode: "cors",
      headers: {
        origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
      },
    });
    return response.json();
  } catch (err) {
    logger.error(`Got error in fetchUsers: ${err}`);
  }
};

const fetchUserDetail = async (api: string, payload: { username: string }) => {
  const response = await fetch(api, {
    method: "POST",
    mode: "cors",
    headers: {
      origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
    },
    body: JSON.stringify(payload),
  });

  if (response.ok) {
    return response.json();
  } else {
    logger.error(`${response.status}`);
    return {};
  }
};

const updateUser = async (
  api: string,
  payload: { username: string; updates: IUserUpdatable }
) => {
  const response = await fetch(api, {
    method: "POST",
    mode: "cors",
    headers: {
      origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
    },
    body: JSON.stringify(payload),
  });

  if (response.ok) {
    return response.json();
  } else {
    logger.error(`${response.status}`);
  }
};

const registerUser = async (
  api: string,
  payload: {
    username: string;
    password: string;
    role: UserRole;
  }
) => {
  const response = await fetch(api, {
    method: "POST",
    mode: "cors",
    headers: {
      origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
    },
    body: JSON.stringify(payload),
  });

  if (response.ok) {
    return response.json();
  } else {
    logger.error(`${response.status}`);
    return {};
  }
};

const signinPrimaryAuth = async (payload: {
  username: string;
  password: string;
}) => {
  const uri = `${ServiceUrls.API_BASE_URL}/${ApiEndpoints.signin}`;
  const response = await fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: {
      origin: ServiceUrls.APP_ORIGIN_HTTP_URL,
    },
    body: JSON.stringify(payload),
  });

  if (response.ok) {
    return response.json();
  } else {
    logger.error(`${response.status}`);
  }
};
