import { createSelector } from "reselect";
import { State } from "./store";

export namespace RouteSelectors {
  const routingSelector = (state: State) => state.routing;

  export const page = createSelector(
    [routingSelector],
    (routing) => routing.page
  );

  export const params = createSelector(
    [routingSelector],
    (routing) => routing.params
  );

  export const queries = createSelector(
    [routingSelector],
    (routing) => routing.queries
  );
}

export { UserManagerSelectors } from "./models/usermanager";
export { MatrixSelectors } from "./models/matrix";
export { ResourceSelectors } from "./models/resource";
export { MediaPlayerSelectors } from "./models/mediaplayer";

// export { SocketSelectors } from "./models/socket";
// export { ClassroomSelectors } from "./models/classroom";
// export { ProseboardSelectors } from "./models/proseboard";
// export { PDFManagerSelectors } from "./models/pdfmanager";
// export { VideoChatSelectors } from "./models/videochat";
// export { QuizManagerSelectors } from "./models/quizmanager";
// export { SpellingManagerSelectors } from "./models/spellingmanager";
// export { TaskManagerSelectors } from "./models/taskmanager";
// export { ClozeManagerSelectors } from "./models/clozemanager";
// export { MathsBoardSelectors } from "./models/mathsboard";
// export { MediaStreamingSelectors } from "./models/mediastreaming";
// export { AudioRoomSelectors } from "./models/audioroom";
// export { TextRoomSelectors } from "./models/textchat";
